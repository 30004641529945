.main-stat-summary {
  // #8898AA
  .stat-card {
    display: flex;
    flex-direction: row;
  }
  .content {
    flex: 5;
    h3 {
      font-size: 0.8em;
      color: #8898aa !important;
      margin: 0;
      margin-bottom: 0.5em;
    }
    h5 {
      font-size: 0.9em;
      color: #4d4f5c !important;
      margin: 0;
      margin-bottom: 0.5em;
    }
    p,
    span {
      font-size: 0.8em;
      color: #4d4f5c !important;
      margin: 0;
    }
    .growth {
      color: $red !important;
      margin-right: 0.5em;
    }
  }
  .icon {
    flex: 1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    img {
      width: 40px;
    }
  }
}
