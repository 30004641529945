.hub-section-title {
  h1 {
    font-size: 1.2em;
    font-weight: lighter;
  }
}

.hub-activities {
  .upcoming-item {
    padding-left: 15px;

    .activity {
      display: flex;
      flex-direction: row;
      img {
        width: 24px;
      }
      .info {
        display: flex;
        flex-direction: column;
        padding-left: 8px;
        align-items: flex-start;
        h1 {
          font-size: 1.1em;
        }
        h3 {
          font-size: 0.8em;
        }
      }
    }

    .due-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      h1 {
        font-size: 0.8em;
      }
      .note {
        h2 {
          font-size: 0.8em;
          color: $maroon !important;
        }
      }
    }
  }
  .bottom-seperator {
    margin-left: 22px;
    padding-bottom: 0.5em;
    margin-bottom: 0;
    border-bottom: 1px solid #4648553a;
  }
  .top-seperator {
    margin-left: 22px;
    padding-top: 0.5em;
    margin-top: 0.5em;
    border-top: 1px solid #4648553a;
  }
}

.hub-tab-content {
  .content-title {
    h1 {
      font-size: 1.2em;
      font-weight: lighter;
    }
  }
}

.hub-profile-nav {
  .list-items {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    li {
      float: left;
    }
    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }

    li a:hover {
      background-color: $maroon-dark;
    }

    .nav-link {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.1em;
      padding: 0.5em;
      width: 100px;
      height: 43px;
      border-bottom: #b3b3b3 solid 1px;

      &:hover {
        border-bottom: $maroon solid 2px;
        font-weight: bold;
        font-size: 1.15em;
      }
    }

    .active-class {
      border-bottom: $maroon solid 2px;
      font-weight: bold;
      font-size: 1.15em;
    }
  }
}

.add-new-title {
  padding-bottom: 5px;
  h1 {
    font-size: 1.2em;
  }
}

.action-panel {
  // margin-left: 3px;
  // margin-top: 8px;
  .panel-top {
    display: flex;
    flex-direction: row;
    img {
      width: 20px;
      margin-right: 10px;
    }
  }
  .deal-btns {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    .hot-deal {
      button {
        width: 150px;
      }
    }
    .associate-deal {
      margin-top: 5px;
      button {
        width: 150px;
      }
    }
  }
  .sort-by {
    margin-top: 20px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    li {
      margin-left: 15px;
    }
    li a:hover {
      background-color: $maroon-dark;
    }
  }
  .filter-by {
    margin-top: 20px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }
    li {
      margin-left: 15px;
    }
  }
}
