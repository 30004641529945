.aggregated-calendar {
  * {
    font-family: $gotham-medium !important;
  }

  .sidebar {
    h2 {
      font-weight: bold;
    }

    // background-color: red;
    .react-calendar {
      width: 100%;
      background: transparent;
      border: none;
    }

    .react-calendar__month-view__weekdays__weekday {
      * {
        text-decoration: none !important;
        color: gray;
      }
    }

    .react-calendar__month-view__days__day,
    .react-calendar__navigation__arrow,
    .react-calendar__navigation__label {
      color: $maroon;
      font-weight: bold;
    }

    .react-calendar__navigation__arrow {
      font-size: 1.5rem;
    }

    .react-calendar__navigation__label__labelText {
      font-size: 1.2rem;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      color: gray;
    }

    .events-container {
      .events {
        background: transparent !important;
        border-left-width: 8px;
      }
    }
  }

  &-content-header {
    .selector-container {
      // background-color: red;
      align-items: center;
    }

    .selectors {
      align-items: center;
      justify-content: center;
      // background-color: green;

      .selection-set {
        background-color: $off-white-light;
        font-size: 1.2rem;
        display: flex;
        flex-direction: row;
        border-radius: 0.5rem;
        padding: 0.1rem;
      }

      .selection-item {
        background-color: $off-white-light;
        cursor: pointer;
        color: $text-blue;
        font-size: 1.2rem;
        border-radius: 0.5rem;

      }

      .content {
        padding: 1rem 2rem;

      }

      .selected {
        background-color: white;
        box-shadow: 0px 1px 2px 3px rgba(62, 57, 107, 0.07);
        border-radius: 0.5rem;
        //  padding: 0.5rem;
      }
    }

    .navigators {
      display: flex;
      flex-direction: row;
      padding: 1rem 0;

      button {
        font-size: 1.2rem;
        padding: 1.2rem;
      }

      // flex:1;
      .date-selector {
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        box-shadow: none !important;
        outline: none !important;
        // background-color: $off-white;
        // background-color: $off-white;
        border-color: $off-white-light;
        color: white;
        border-radius: 0.5rem;
        margin: 0 1rem;

        .clickable {
          outline: none !important;
          box-shadow: none !important;
          outline: none !important;
          background-color: $off-white-light;
          border: none;
          padding: 1rem;

          border-radius: 0.5rem;

          &-picker {
            margin: 0 1rem;
          }

          &:hover {
            background-color: darken($color: $off-white-light, $amount: 20);
          }
        }

      }
    }
  }
}


.calendar-container {

  width: 100%;
  text-align: center;

  tbody {
    width: 100%;

    tr {
      display: flex;

      td {
        flex: 1;
      }
    }

    .weeks {
      color: lighten($color: $text-blue, $amount: 60);
      font-weight: bold;
      text-transform: uppercase;

      .week-day {
        font-size: 1.2rem;
        flex: 1;
        // border: 1px solid lighten($color: $text-blue, $amount: 75);
        // border-bottom: none;
        border-bottom: 1px solid lighten($color: $text-blue, $amount: 75);
        padding: 2rem 0 1.5rem;

        .number {
          color: $text-blue;
          font-size: 1.4rem;
        }
      }

      .week-day-filler {
        color: transparent;
        font-size: 1rem;
        flex: none !important;
        padding: 1rem;
      }

      .day-data {
        text-align: left;
        margin-left: 1rem;
      }
    }

    .slot,
    .day,
    .empty-slot {
      color: $text-blue;

      // font-weight: bold;
      b {
        font-family: $gotham !important;
      }

      font-size: 1.2rem;
      // padding: 1rem;
      padding-right: 1rem;
      border: 1px solid lighten($color: $text-blue, $amount: 75);
      border-top: none;

      .date-number {

        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;

        // margin-bottom: 1rem;
        .number-container {
          font-size: 1.2rem;
          // font-weight: bold;
          width: 2.2rem;
          height: 2.2rem;
          padding: 0.25rem 0.4rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .day {
      b {
        font-size: 0.8rem;
      }

      min-height: 18rem;
    }



    .time {
      color: lighten($color: $text-blue, $amount: 50);
      border-bottom: 0;
      font-size: 1rem;
      padding: 1rem;
      padding-bottom: 0;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex: none !important;
      border-left: none !important;
      width: 70px;


      .value {
        transform: translateY(0.5rem);
      }
    }

    .current-day {
      .number-container {
        border-radius: 50%;
        background-color: $maroon;
        color: $white;
        margin: 0;
      }
    }
  }
}

.day-view {
  td {
    flex: 7 !important;
  }

  .time {
    flex: none !important;
    // flex: 1!important;
  }

  .events-container {
    display: flex;

    .event-item {
      flex: 1;

      .events {
        margin-bottom: 0 !important;
        margin-right: 1rem;
        padding: 1rem 1rem;
        height: 4rem;
        position: absolute;
        width: 99%;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
}


.schedule-view {
  .day-schedule {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    border-bottom: $off-white-light solid 2px;
    align-items: flex-start;

    .date {
      width: 3rem;
      font-size: 1.2rem;
      font-weight: bold;
      font-family: $font-family-gotham-medium;
      color: $maroon;
    }

    .month-day {
      text-transform: uppercase;
      width: 12rem;
      padding: 0.2rem 0rem 0 2rem;
      font-size: 1rem;
      font-weight: bold;
      font-family: $font-family-gotham-medium;
      color: darken($color: $off-white, $amount: 20);
    }

    .events-container {
      min-height: 1rem;

      .event {
        display: flex;
        flex-direction: row;
        color: $maroon;
        margin-bottom: 1rem;
        color: #7D8793;

        .time {
          width: 15rem;
          padding: 0 4rem 0 1rem;
          border-left: 0.8rem solid red;
        }

        .calls {
          border-left: 0.8rem solid #F19235;
          background: transparent !important;
        }

        .online-meetings {
          border-left: 0.8rem solid #DE412E;
          background: transparent !important;
        }

        .physical-meetings {
          border-left: 0.8rem solid #53B27A;
          background: transparent !important;
        }

        .tasks {
          border-left: 0.8rem solid #D0DE2E;
          background: transparent !important;
        }

        .name {
          .customer {
            font-weight: bold;
          }
        }
      }
    }

  }
}

.create-event-modal-container {
  // width: 60%;
  max-height: 80vh !important;
  .event-creation-form{
    max-height: 70vh !important;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .form-success {
    .content {
      text-align: center;

      img {
        width: 8rem;
        padding: 1rem 0 3rem;
      }

      .link {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding-top: 1rem;
        cursor: pointer;
        color: lighten($color: $text-blue, $amount: 10);

        h6 {
          margin-bottom: 0;
        }


        img {
          margin-left: 1rem;
          width: 1rem;
          padding: 0rem;
        }
      }
    }
  }
}

.create-event-modal {

  .deal-selectors {
    display: flex;
    text-align: center;

    .selectioncd {
      flex: 1
    }
  }

  .forms {

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      h2 {
        flex: 1;
      }

      .image {

        padding: 1rem;
        cursor: pointer;

        img {
          width: 1rem;
          // background: red;
        }
      }
    }
  }

  .selectors {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .selection-item {
      outline: none;
      box-shadow: none;
      border: none;
      padding: 1.5rem 4rem;
      background-color: $off-white-light;
      cursor: pointer;
      margin: 1rem;
      border-radius: 10px;

      &:hover {
        background-color: darken($color: $off-white-light, $amount: 10);

      }

      text-align: center;

      .icon {
        margin-bottom: 1rem;

        img {
          width: 1.8rem;
        }
      }
    }
  }
}

.customer-deals {
  border-left: 2px solid $off-white-light;
}

.events-container {
  min-height: 4rem;


  .event-item {
    position: relative;
    // transition: transform 0.2s;
    // &:hover{
    //   transform: translateY(-1px);
    // }
    // z-index: 19;
  }

  .event-detail {
    // z-index: 10;
    position: absolute;
    left: -22rem;
    box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    top: 0;
    width: 20rem;
    min-height: 10rem;
    background: white;

    .content {
      text-align: left;
    }

    .title {
      border-left: 1.5rem solid $maroon;
      padding-left: 1rem;
      font-size: 1.2rem;
    }

    .text {
      text-align: left;
    }

    .close {
      cursor: pointer;
      text-align: right;

      img {
        width: 1rem;
      }
    }

  }

  .events {
    cursor: pointer;
    background-color: lighten($color: $maroon, $amount: 75);
    border-left: 4px solid $maroon;
    text-align: left;
    padding: 0.5rem 1rem;
    font-weight: normal;
  }

  .calls {
    background-color: lighten($color: #F19235, $amount: 75);
    border-left: 4px solid #F19235;
  }

  .online-meetings {
    background-color: lighten($color: #DE412E, $amount: 30);
    border-left: 4px solid #DE412E;
  }

  .physical-meetings {
    background-color: lighten($color: #53B27A, $amount: 30);
    border-left: 4px solid #53B27A;
  }

  .tasks {
    background-color: lighten($color: #D0DE2E, $amount: 30);
    border-left: 4px solid #D0DE2E;
  }

}


// -----------------------------------
// User picker
// -----------------------------------

.email-picker {
  position: relative;

  .adder {
    display: flex;
    flex-direction: row;
    align-items: center;

    .field {
      flex: 1;
      margin-right: 1rem;
    }
  }

  .selected {
    list-style: none;
    // display: flex;
    // flex-direction: row;

    li {
      flex: 1;
      display: flex;
      flex-direction: row;

      .text {
        flex: 1;
      }

      img {
        cursor: pointer;
        padding: 0.2rem;
        width: 1rem;
      }
    }
  }

  .suggestions {
    margin-top: -0.5rem;
    max-height: 8rem;
    overflow-y: scroll;
    position: absolute;
    display: block;
    width: 100%;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    // line-height: $input-line-height;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    color: #8898aa;
    border-color: darken($input-border-color, 10%);
    outline: 0;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.100);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);
    list-style: none;

    li {
      padding: $input-padding-y $input-padding-x;
      cursor: pointer;
      background-color: $input-bg;

      &:first-child {
        border-radius: 5px 5px 0 0;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }

      &:hover {
        background-color: darken($color: $input-bg, $amount: 10);
      }

      // background: red;
    }
  }
}

//--------------------------------
//select calendar dropdown
//--------------------------------

.align-calendar-items-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

}

.calendar-main {
  position: relative;
  // overflow: hidden;
  .drop-down {
    position: absolute;
    left: 100px;
    top: 0;
    background-color: $white;
    width: 160px;
    z-index: 100;
    ul {
      list-style-type: none;
      margin: 0;
      overflow-y: scroll;
      text-align: left;
      // max-height: 200px;
      min-height: 200px;
      li {
        padding-left: 1em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        color: black;
        &:hover {
          background-color: #e3e3e4;
        }
      }
    }
  }
}

.calendar-main-actions {
  display: flex;

  h1 {
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .status-update-btn {
    position: relative;
    margin-bottom: 10px;
    img {
      margin-left: 12px;
      width: 9px;
    }
  }

  .right-container {
    flex: 1;
  }

  .optimze-items {
    display: flex;
    position: relative;

    button {
      min-width: 150px;
      text-align: left;

      img {
        margin-top: 8px;
      }
    }

    .drop-down {
      position: absolute;
      left: 0;
      top: 34px;
      background-color: $white;
      width: 100%;
      z-index: 1;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: black;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }

  .calendar-view-as {
    display: flex;
    position: relative;
    

    button {
      min-width: 150px;
      text-align: left;

      img {
        margin-top: 8px;
      }
    }

    .drop-down {
      position: absolute;
      left: 0;
      top: 34px;
      background-color: $white;
      width: 100%;
      z-index: 1;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: black;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }
}

.btn-crm {
 
  &-white {
    box-shadow: 2px 2px 3px #565a7538 !important;
    outline: none !important;
    background-color: #ffffff;
    border-color: #ffffff;
    color: $maroon;
    border-radius: 0.35rem;

    &:hover {
      color: $maroon;
    }
  }
}

