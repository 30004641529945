.containerProducts {
    margin-bottom: 30px;
}

.nameContent {
    margin: 0 0 0 0;
}

.productsName {
    font-weight: bold;
}

.cardImageContent {
    flex: 1 1 auto;
    padding: 0 0 0 0;
}

.cardDescriptionContent {
    flex: 1 1 auto;
    padding: 1.2rem;
}

.cardProducts {
    flex: 1 1 auto;
    padding: 0 0 0 0;
}

.fontProducts {
    font-style: italic;
    text-align: center;
}

.imageProducts {
    // width: auto;
    // height: 75px;
   max-width: 100%;
   max-height: 100%;
   display: block;
}

.imageProductsContainer {
    height: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
}