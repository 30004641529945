.EditCustomer {
  width: 65%;
  .edit-header {
    .header-title {
      h1 {
        font-size: 1.3rem;
      }
    }
  }
}
