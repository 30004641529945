.auth-screen {
  display: flex;
  flex-direction: row;
  // background-color: $accent;
  background: linear-gradient($accent, $maroon);

  .form-wrapper {
    flex: 2;
    display: flex;
    background-color: $off-white;
    justify-content: center;
    align-items: center;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    .auth-form {
      padding: 3em;
      justify-content: center;
      align-items: center;
      .header {
        text-align: center;
        img {
          width: 50%;
        }
        h4 {
          font-weight: 400;
          padding-top: 0.6em;
          padding-bottom: 0.6em;
        }
      }
      .form-group {
        margin-top: 1.8em;
        margin-bottom: 1.8em;
      }
      .button-container {
        margin-top: 4em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .error-message {
        h4 {
          color: red !important;
        }
        text-align: center;
      }
    }
  }

  .features {
    flex: 5;
    display: flex;
    background-color: $maroon;
    padding: 3em;

    -webkit-clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    clip-path: polygon(0 20%, 100% 0, 100% 100%, 0% 100%);
    
    .content {
      flex: 1;
      h1,
      p {
        color: $white !important;
      }
      h1 {
        font-size: 2em;
      }
    }

    .image {
      flex: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      img{
        width: 80%;
      }
    }
  }
}
