.react-select-container {
  .react-select__control {
    width: 100%;
    height: $input-height;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    background-color: $input-bg;
    padding: 0 $input-padding-x;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    color: #8898aa;
    border-color: darken($input-border-color, 10%);
    outline: 0;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.096);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
      color: $input-focus-color;
      border-color: darken($input-border-color, 10%);
      outline: 0;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    }

    // Placeholder
    &::placeholder {
      color: $input-placeholder-color;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
    .react-select__value-container {
      padding: 0;
      margin: 0;
      // background-color: red;
      // justify-content: center;
      align-items: center;
    }
    .react-select__indicators {
      padding: 0;
      margin: 0;
    }
    // padding: 0px 0px 0px 0px;
    margin: 0;
  }
  .react-select__menu {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    // .react-select__menu-list {
    // }
    .react-select__option {
      border-bottom: 1px solid #8898aa1c;
    }
  }
}