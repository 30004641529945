.modal {
  display: block;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.modal-dialog {
  z-index: 1051;
  position: fixed;
  margin: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(1.2);
  
  // max-height: 80vh !important;
  .modal-content {
    // max-height: 80vh !important;
    // overflow-y: scroll;
    // width: 150%;
    padding: 0 25px
  }
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
  // counteract the pointer-events: none; in the .modal-dialog
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  @include border-radius($border-radius-lg);
  @include box-shadow($modal-content-box-shadow-xs);
  // Remove focus outline from opened modal
  outline: 0;
}

.modal-body {
  position: relative;
  // Enable `flex-grow: 1` so that the body take up as much space as possible
  // when should there be a fixed height on `.modal-dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}