.calendarBody {
    // margin-bottom: -250px;
    width: 300px;
    height: 200px;
    min-height: 22.8em;
    padding-top: 0px;
    // font-size: 5px;
}

.Calendar__weekRow {
    font-size: 7px;
}

.Calendar__weekDay {
    font-size: 7px;
}

.Calendar__day {
    padding: 0 15px;
}

.Calendar__monthYearContainer {
    font-size: 10px;
}

.Calendar__section {
    padding-top: 0px;
}