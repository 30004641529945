$theme-color: #dc1a30;
$theme-color2: #F1F3F9;
$maroon: #172B4D;
$maroon-dark: #0c1e3d;
$text-blue: #172B4D;
$off-white: #DCE0E3;
$off-white-light: #F1F3F9;
$gotham: "Gotham-Book", sans-serif;
$gotham-medium: "Gotham-Medium", sans-serif;
$gotham-bold: "Gotham-Bold", sans-serif;

// $red: blue;
$danger: $red !default;


// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

$modal-dialog-margin: 0.5rem !default;
$modal-dialog-margin-y-sm-up: 1.75rem !default;

$modal-title-line-height: $line-height-base !default;

$modal-content-bg: $white !default;
$modal-content-border-color: rgba($black, 0.2) !default;
$modal-content-border-width: $border-width !default;
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.5) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.5) !default;

$modal-backdrop-bg: $black !default;
$modal-backdrop-opacity: 0.5 !default;
$modal-header-border-color: $gray-200 !default;
$modal-footer-border-color: $modal-header-border-color !default;
$modal-header-border-width: $modal-content-border-width !default;
$modal-footer-border-width: $modal-header-border-width !default;
$modal-header-padding: 1rem !default;

$modal-lg: 800px !default;
$modal-md: 500px !default;
$modal-sm: 300px !default;

$modal-transition: transform 0.3s ease-out !default;
