.side-bar {
  transition: width 0.5s;
  // background-color: #f4f5fa;
  background-color: $maroon;

  .branding {
    background-color: #f4f5fa;
    // box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1);
    height: 60px;
    align-items: center;
    display: flex;
    padding: 1em 1.5em;

    img {
      width: 100%;
    }

    h1 {
      color: $white;
      padding: 10px;
    }
  }

  .toggle-sidebar {
    background-color: #f4f5fa;

    .nav-link {
      padding: 1em 1.5em;

      border-top-right-radius: 30px;
      background-color: $maroon;
    }

    img {
      width: 20px
    }
  }

  nav {

    height: calc(100vh - 120px);
    overflow-y: auto;

    ul,
    li {
      margin: 0;
      padding: 0;
    }

    .nav-link {
      background-color: $maroon;
      outline: none;
      cursor: pointer;
      padding: 1em 1.5em;
      display: flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;
      border-right: transparent solid 2px;
      outline: none;

      &:hover {
        // border-right: #d8001a solid 5px;
        background-color: $maroon-dark;
      }

      span {
        display: inline-block;
        font-size: 0.9em;
        color: $white;
        margin: 0;
        padding-left: 10px;
        font-weight: 500;
      }

      img {
        width: 15px;
      }
    }

    .active-link {
      border-right: $theme-color solid 2px;
      background-color: $maroon-dark;
    }

    .has-sub {
      position: relative;
      // > a::after {
      //   font-size: 10px;
      //   content: "➤";
      //   color: #2c303b;
      //   position: absolute;
      //   right: 20px;
      //   // top: 14px;
      //   transform: rotate(0deg);
      //   transition: transform 0.3s ease-in-out;
      // }

      .sub-nav-item {
        font-size: 0.85em;
        // display: none;
        display: block;
        transition: display 1.5s;
      }

      // &:hover {
      //   > a::after {
      //     transition: transform 0.3s ease-in-out;
      //     transform: rotate(90deg);
      //   }
      //   .sub-nav-item {
      //     display: block;
      //   }
      // }
    }
  }
}