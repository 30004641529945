.LeadMoreDetails{
    transform: scaleY(1);
    transform: scaleX(1);
    transform: translate(-50%, -50%);

    .more-details-container {
      display: grid;
      grid-template-columns: repeat(1,minmax(0,1fr));
      max-height: 450px;
      overflow-y: auto;
    }

    .field-container {
      margin: 7px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-flow: column wrap;
    }
}