.quotations {
  .customer-detail {
    padding: 2rem;
    background-color: #f8fafc;
    border-top-left-radius: 0.8em;
    border-top-right-radius: 0.8em;

    h2 {
      font-size: 1.5rem;
    }

    p {
      margin: 0;
      padding: 0;
      color: $text-blue;
    }

    .categorization {
      text-align: right;

      .category {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        img {
          width: 1.1rem;
        }

        .text-red {
          color: red;
        }

        .text-blue {
          color: blue;
        }
      }
    }
  }

  .content {
    padding: 2rem;

    .label {
      // display: block;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding-left: 3rem;
      padding-right: 3rem;
      margin-bottom: 2rem;

      h3 {
        background-color: $maroon;
        color: $white !important;
        padding: 0.5rem;
        border-radius: 1rem;
      }
    }

    .content-card {
      text-align: left;

      h3 {
        text-align: left;
      }
    }

    .document-card {
      text-align: left;

      img {
        width: 100%;
      }

      label {
        padding-left: 1rem;
        padding-right: 1rem;
        font-size: 0.9rem;
      }

      .quotation {
        width: 150px;

        .qt-img {
          height: 90px;
          width: 80px;
        }
      }
    }
  }

  .task-list {
    margin-top: 32px;
    .task-item {
      background: #f7fafc;
      &:nth-child(2) {
        background: $white;
      }

      display: flex;

      .input {
      }

      .label {
        flex: 1;
      }
    }
  }
}
