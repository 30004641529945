.task-summary {
  display: flex;
  justify-content: center;
  align-items: center;
  .delayed {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    position: relative;
    top: -2em;
    h2 {
      font-size: 6em !important;
      font-weight: bold;
      padding: 0;
      margin: 0;
    }
    p {
      font-size: 1.2em;
      position: relative;
      top: 1em;
    }
  }
}
