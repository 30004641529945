header {
  // background-color: #4e0911;
  // box-shadow: 1px 0px 30px rgba(0, 0, 0, 0.1);
  height: 60px;
  display: flex;
  align-items: center;
  cursor: pointer;

  .topbar-function {
    // img{}
    display: flex;
    justify-content: left;
    align-items: center;
  }

  .branding {
    height: 60px;
    align-items: center;
    display: flex;
    padding: 1em 1.5em;
    width: 305px;

    img {
      width: 100%;
    }

    h1 {
      color: $white;
      padding: 10px;
    }
  }

  .nav-container {
    width: 100%;
    background-color: $maroon;
    border-bottom-left-radius: 20px;
  }

  .search-bar-container {
    display: flex;
    align-items: center;
    right: 20px;
    .drop-down {
      position: absolute;
      background-color: $white;
      top: 3.5em;
      right: 2em;
      width: 450px;
      height: 200px;
      z-index: 100;

      // left: 0;
      // bottom: 0;
      ul {
        list-style-type: none;
        list-style: none;
        margin: 0;
        overflow-y: scroll;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;

          img {
            width: 15px;
            margin-right: 1em;
          }

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }

  .settings-container {
    background-color: $maroon;
    height: 60px;
    align-items: center;
  }

  .nav {
    height: 60px;
    display: flex;
    flex-direction: row;
    .list-items {
      width: 100%;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
        background-color: $maroon;
      }
      li {
        float: left;
      }
      
      li :last-child {
        float: right;
      }

      li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
      }
      
      li a:hover {
        background-color: $maroon-dark;
      }
    }
  }

  .top-nav-drop-down {
    background-color: $maroon;
    top: 5rem;
    left: 5em;
    width: 130px;
    border-radius: 0.8em;
    z-index: 5;
  
    // left: 0;
    // bottom: 0;
    ul {
      list-style-type: none;
      list-style: none;
      margin: 0;
  
      li {
        padding-left: 1em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
  
        img {
          width: 15px;
          margin-right: 1em;
        }
  
        &:hover {
          background-color: #e3e3e4;
        }
      }
    }
  }

  .nav-link {
    outline: none;
    cursor: pointer;
    padding: 0.3em;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    border-right: transparent solid 2px;
    outline: none;
    width: 80px;
    height: 56px;
    margin-top: 3px;

    &:hover {
      // border-right: #d8001a solid 5px;
      background-color: $maroon-dark;
    }
    span {
      display: inline-block;
      font-size: 0.8em;
      color: #EDEDED;
      margin-top: 4px;
      // font-weight: 500;
      font-family: "Gotham-Book", sans-serif !important;
    }
    img {
      width: 20px;
      margin-top: 2px;
    }
  }

  .active-link {
    border-right: $theme-color solid 2px;
    background-color: $maroon-dark;
  }

  .search-bar {
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    background-color: #f7fafc;
    .search-img-container{
      display: flex;
      justify-content: center;
      align-items: center;
      .search-img {
        width: 16px;
        margin-left: 0.8rem;
        margin-right: 0.4rem;
      }
    }
    .clear-img-container{
      display: flex;
      justify-content: center;
      align-items: center;
      .clear-img {
        width: 13px;
        margin-left: 0.7rem;
        margin-right: 0.7rem;
      }
    }
    input{
      padding: 0.5rem;
      border: none;
      outline: none;
      box-shadow: none;
      background: transparent;
    }
  }

  .user-wrapper {
    .user {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-right: 23px;

      p {
        font-weight: bold;
        color: white;
        font-weight: 700;
        margin: 0;
        margin-right: 0.8em;
        font-size: 1.1em;
      }

      .avatar {
        width: 36px;

        img {
          width: 100%;
          // border-radius: 50%;
        }
      }
    }

    .drop-down {
      position: absolute;
      background-color: $white;
      top: 3.5em;
      right: 2em;
      width: 180px;
      z-index: 100;

      // left: 0;
      // bottom: 0;
      ul {
        list-style-type: none;
        list-style: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;

          img {
            width: 15px;
            margin-right: 1em;
          }

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }
}