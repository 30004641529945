.suggestions-container{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  row-gap: 1rem;
  position: relative;
  height: 100%;
  .day{
    color: rgba(0, 255, 255, 0.808);
    font-size: 1.2rem !important;
  }
  .card_container{
    display: flex;
    column-gap: 4rem;
    flex-wrap: wrap;
    row-gap: 2rem;
    .card{
      flex: 40%;
      // max-width: 50%;
      background-image: linear-gradient(to right, #171e35, #29183f );
      border-radius: 10px;
      color: white;
      padding: 1.5rem 3rem;
      height: fit-content;
      row-gap: 1rem;
      // padding: 0 1rem;
      // margin: 0 2rem;
      // margin-left: 2rem;

      .card_header{
        display: flex;
        // justify-content: ;
        justify-content: space-between;
        letter-spacing: 1px;
        .title{
          color: white;
          font-weight: bold !important;
        }
        .time{
          color:cyan !important;
          font-size: 1.5rem;
        }
      }
      .card_actions{
        display: flex;
        column-gap: 1.5rem;
        .action{
          padding: 0.5rem 1rem;
          border-radius: 5px;
        }
        .action-cyan{
          color: #6fe1f07a;
          border: 2px solid #6fe1f07a;
        }
        .action-yellow{
          color: #ffd16d7c;
          border: 2px solid #ffd16d7c;
        }
        .action-red{
          color: #da86827c;
          border: 2px solid #da86827c;
        }
      }
    }
  }
  .suggestions_back{
    position: absolute;
    // top: 0;
    bottom: 1rem;
    background-color: transparent;
    border: 1px solid white;
    width: fit-content;
    padding: 0.3rem 1rem;
    border-radius: 5px;
    color: white;
    font-weight: bold;
    outline: none;
  }
  .suggestions_back:hover{
    background-color: white;
    color: black;
    transition: background-color 0.8s ease;
  }
}