.global-notification-container {
  font-size: 14px;
  box-sizing: border-box;
  position: fixed;
  z-index: 999999;
  animation: global-notification-popup 3.5s;
  &.global-notification-position {
    top: 12px;
    right: 12px;
  }
  @keyframes global-notification-popup {
    0% {
      right: -500px;
    }
    25% {
      right: 12px;
    }
    75% {
      right: 12px;
    }
    100% {
      right: -500px;
    }
  }
  @media (max-width: 767px) {
    left: 0;
    right: 0;
  }
  .global-notification {
    p {
      color: white;
    }
    background: #ffffff;
    transition: .3s ease;
    position: relative;
    pointer-events: auto;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 30px;
    width: 300px;
    border-radius: 3px 3px 3px 3px;
    box-shadow: 0 0 12px #999999;
    color: #000000;
    opacity: 0.9;
    -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=90);
    filter: alpha(opacity=90);
    background-position: 15px center !important;
    background-repeat: no-repeat !important; // Prevent annoying text selection
    -webkit-user-select: none;
    /* Chrome all / Safari all */
    -moz-user-select: none;
    /* Firefox all */
    -ms-user-select: none;
    /* IE 10+ */
    user-select: none;
    /* Likely future */
    &:hover {
      box-shadow: 0 0 12px #000000;
      opacity: 1;
      cursor: pointer;
    }
    .global-notification-title {
      font-weight: bold;
      font-size: 16px;
      text-align: left;
      margin-top: 0;
      margin-bottom: 6px;
      word-wrap: break-word;
    }
    .global-notification-message {
      margin: 0;
      text-align: left;
      word-wrap: break-word;
    }
  } // Themes
  .success {
    background: #51A351;
    color: #ffffff;
    padding: 15px 15px 15px 50px;
  }
  .danger {
    background: rgb(250, 47, 47);
    color: #ffffff;
    padding: 15px 15px 15px 50px;
  }
  .info {
    background: rgb(22, 148, 206);
    color: #ffffff;
    padding: 15px 15px 15px 50px;
  }
  button {
    position: relative;
    right: -0.3em;
    top: -0.3em;
    float: right;
    font-weight: bold;
    color: #FFFFFF;
    text-shadow: 0 1px 0 #ffffff;
    opacity: 0.8;
    line-height: 1;
    font-size: 16px;
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    &:hover {
      opacity: 1;
    }
  }
}