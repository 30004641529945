.ViewDiscounts{
    width: 300px;

    .content {
        display: flex;
        align-items: center;
        color: #4d4f5c;
        margin-top: 0.5em;
        font-size: 0.7em;
      }
}