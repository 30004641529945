@font-face {
  font-family: "Gotham-Book";
  src: url("./fonts/GothamBook.ttf");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./fonts/GothamMedium.otf");
}

@font-face {
  font-family: "Gotham";
  src: url("./fonts/gotham.woff");
}

@import "./vendors/bootstrap/mixins";
@import "./vendors/bootstrap/bootstrap-grid.scss";
@import "./vendors/bootstrap/variables";
@import "./base/variables.scss";
@import "./base/core.scss";
@import "./custom/SetMonthlyTarget.scss";
@import "./custom/AddVisit.scss";
@import "./custom/TaskAgentName.scss";
@import "./custom/AddTask.scss";
@import "./custom/Analysis.scss";
@import "./custom/ViewDiscounts.scss";
@import "./custom/GlobalNotifications.scss";
@import "./custom/CRMHubs.scss";
@import "./custom/NewCustomer.scss";
@import "./custom/Users.scss";
@import "./custom/ConfirmModal.scss";
@import "./custom/CRMDashboard.scss";
@import "./custom/AddMeetingSlot.scss";
@import "./custom/DealStageUpdateConfirm.scss";
@import "./vendors/bootstrap/card";
@import "./vendors/bootstrap/forms";
@import "./vendors/bootstrap/utilities";
@import "./layouts/header";
@import "./layouts/sidebar";
@import "./layouts/body";
@import "./layouts/footer";
@import "./components/index";
@import "./crm/salesFunnel";
@import "./crm/quotations";
@import "./custom/LeaveManagmentSummary.scss";
@import "./custom/LeaveProceed.scss";
@import "./custom/AgentLeaveProfile.scss";
@import "./custom/UpdateLeadStage.scss";
@import "_calendar";
@import "./custom/LeadMoreDetails.scss";
@import "./custom/DealMoreDetails.scss";
@import "./custom/NewForm.scss";
@import "./custom/LeadsTable.scss";
@import "./custom/ConvertToSale.scss";
@import "./custom/EditCustomerModal.scss";
@import "./custom/ScrollModal.scss";
@import "./custom/WorkflowSuggestions.scss";

#root {
  background-color: #f4f5fa;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100vh;
}

.card-body {
  box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
  border-radius: 0.8em;
  border-color: transparent;
  outline: none;

  &:hover {
    box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.07);
  }
}

a {
  &:hover {
    text-decoration: none !important;
  }
}

section {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

h1,
h2,
h3,
h4,
h5,
hr {
  color: $text-blue !important;
  margin-bottom: 0rem;
}

hr {
  color: #4648553a;
}

.util-icons {
  cursor: pointer;

  img {
    width: 20px;
    margin-right: 20px;
  }
}

.email-form {
  display: flex;
  flex-direction: column;
  width: 75vw;
  height: 70vh;

  .email-fields {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;

    .email-input {
      flex: 1;
      padding: 2px 5px 2px 5px;
      font-weight: 2.2rem;
      font-family: "Gotham-Book", sans-serif;
    }

    .body {
      height: 200px;
    }
  }
  .email-input-label {
    width: 80px;
  }

  .action-btns {
    margin-top: 5px;
  }

  .email-composer {
    overflow-x: auto;
    overflow-y: auto;
    height: 500px;
  }
}

.single-file-view {
  display: flex;
  margin-top: 10px;

  .left-pane {
    float: left;
    height: 66vh;
    width: 45%;
    text-align: center;
    margin-right: 23px;
    border-radius: 0.8em;
    border-color: transparent;
    .file-preview {
      height: 66vh;
      background-color: rgb(228, 228, 228);
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
    }
  }

  .right-pane {
    float: left;
    width: 55%;
    position: relative;
    .toggle-buttons {
      display: flex;
      flex-wrap: nowrap;
      .toggle-button {
        box-shadow: 2px 2px 3px #565a7538 !important;
        outline: none !important;
        border: none !important;
        border-radius: 0.35rem;
        width: 150px;
        padding: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .active {
      background-color: $maroon !important;
      border-color: $maroon !important;
      color: white !important;
    }
    .top-info {
      display: flex;
      justify-content: center;
      background: white;
      text-align: center;
      height: 39%;
      max-height: 400px;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
      overflow-y: auto;
      .file-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .upload-new {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .file-history {
        padding: 10px;
        .file-history-item {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          text-align: left;
          padding: 10px;
          .file-name {
            width: 100px;
          }
          .file-type {
            width: 100px;
          }
          .file-size {
            width: 100px;
          }
          .file-date {
            width: 150px;
          }
          .single-file-view {
            width: 50px;
          }
        }
      }

      .file-activities {
        padding: 10px;
        .activity-item {
          display: grid;
          grid-template-columns: auto auto;
          text-align: left;
          padding: 10px;
          grid-column-gap: 30px;
          .activity-description {
          }
          .activity-date {
            text-align: right;
          }
        }
      }
      .action-buttons {
        margin-top: 10px;
        .spacing {
          margin-right: 10px;
        }
      }
    }
    .comments-section {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 47%;
      margin-top: 10px;
      background: white;
      padding: 30px;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;

      .comments {
        overflow-y: auto;
        height: 80%;
      }
      .single-comment {
        margin-bottom: 10px;
        display: flex;
        .user {
          flex: 0 1 auto;
          font-weight: 600;
        }

        .comment {
          overflow-wrap: break-word;
          margin-right: 10px;
          margin-left: 10px;
          width: 100%;
        }

        .time {
          white-space: nowrap;
        }
      }

      .comments-form {
        display: flex;
        margin-top: 20px;
        padding-bottom: 30px;
        width: 100%;

        .input {
          width: 100%;
          margin-right: 20px;
          padding: 0 8px 0 8px;
        }
      }
    }
  }
}

.public-page {
  min-width: 100%;
  height: 100vh;
  .branding {
    img {
      width: 200px;
    }
  }
  .error {
    color: #e04b5e;
  }
}
.single-file-view-public {
  display: flex;
  margin: 10px 50px 10px 50px;
  flex: 1;
  .left-pane {
    float: left;
    height: 85vh;
    width: 55%;
    text-align: center;
    margin-right: 23px;
    border-radius: 0.8em;
    border-color: transparent;
    .file-preview {
      height: 85vh;
      background-color: rgb(228, 228, 228);
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
    }
  }

  .right-pane {
    float: left;
    width: 45%;
    position: relative;
    .toggle-buttons {
      display: flex;
      flex-wrap: nowrap;
      .toggle-button {
        box-shadow: 2px 2px 3px #565a7538 !important;
        outline: none !important;
        border: none !important;
        border-radius: 0.35rem;
        width: 150px;
        padding: 15px;
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    .active {
      background-color: $maroon !important;
      border-color: $maroon !important;
      color: white !important;
    }
    .top-info {
      display: flex;
      justify-content: center;
      background: white;
      text-align: center;
      height: 39%;
      max-height: 400px;
      margin-bottom: 10px;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
      overflow-y: auto;
      .file-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .upload-new {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .file-history {
        padding: 10px;
        .file-history-item {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          text-align: left;
          padding: 10px;
          .file-name {
            width: 100px;
          }
          .file-type {
            width: 100px;
          }
          .file-size {
            width: 100px;
          }
          .file-date {
            width: 150px;
          }
          .single-file-view {
            width: 50px;
          }
        }
      }

      .file-activities {
        padding: 10px;
        .activity-item {
          display: grid;
          grid-template-columns: auto auto;
          text-align: left;
          padding: 10px;
          grid-column-gap: 30px;
          .activity-description {
          }
          .activity-date {
            text-align: right;
          }
        }
      }
      .action-buttons {
        margin-top: 10px;
        .spacing {
          margin-right: 10px;
        }
      }
    }
    .comments-section {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 47%;
      margin-top: 10px;
      background: white;
      padding: 30px;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;

      .comments {
        overflow-y: auto;
        height: 80%;
      }
      .single-comment {
        margin-bottom: 10px;
        display: flex;
        .user {
          flex: 0 1 auto;
          font-weight: 600;
        }

        .comment {
          overflow-wrap: break-word;
          margin-right: 10px;
          margin-left: 10px;
          width: 100%;
        }

        .time {
          white-space: nowrap;
        }
      }

      .comments-form {
        display: flex;
        margin-top: 20px;
        padding-bottom: 30px;
        width: 100%;

        .input {
          width: 100%;
          margin-right: 20px;
          padding: 0 8px 0 8px;
        }
      }
    }
  }
}

.blur-backgroud {
  background: rgba(241, 243, 249, 0.1); // Make sure this color has an opacity of less than 1
  backdrop-filter: blur(5px); // This be the blur
  height: 100vh;
  width: 100%;
}

.btn,
.btn-sm,
.btn-danger,
.btn-accent {
  box-shadow: none !important;
  outline: none !important;
}

.btn-crm-sm {
  border: 1px solid $maroon !important;
}

.btn-crm-dp {
  box-shadow: none !important;
  outline: none !important;
  color: black;
  border-radius: 0.7rem;
  border-color: #000000;
}

.btn-crm {
  &-maroon {
    box-shadow: 2px 2px 3px #565a7538 !important;
    outline: none !important;
    background-color: $maroon;
    border-color: $maroon;
    color: white;
    border-radius: 0.35rem;

    &:hover {
      color: white;
    }
  }

  &-off-white {
    box-shadow: none !important;
    outline: none !important;
    background-color: $off-white;
    border-color: $off-white;
    // color: white;
    border-radius: 0.35rem;

    &:hover {
      // color: white;
    }
  }

  &-white {
    box-shadow: 2px 2px 3px #565a7538 !important;
    outline: none !important;
    background-color: #ffffff;
    border-color: #ffffff;
    color: $maroon;
    border-radius: 0.35rem;

    &:hover {
      color: $maroon;
    }
  }
}

.btn-outline-crm {
  box-shadow: none !important;
  outline: none !important;
  background-color: $theme-color2;
  color: $maroon;
  border: 1px solid $maroon;
  border-radius: 0.35rem;

  &:hover {
    color: $theme-color2;
    background-color: $maroon;
  }
}

.btn-outline-fixed-crm {
  box-shadow: none !important;
  outline: none !important;
  background-color: $theme-color2;
  color: $maroon;
  border: 1px solid $maroon;
  border-radius: 0.35rem;
}

.btn-no-shadow {
  box-shadow: none !important;
}

.btn-add-with-img {
  img {
    margin-bottom: 3px;
    margin-left: 8px;
  }
}

.btn-approve {
  width: 5.5rem !important;
  transform: translate(10%, -15%) scale(0.9) !important;
}

.btn-cancel {
  width: 4.75rem !important;
  transform: translate(20%, -15%) scale(0.9) !important;
}

.btn-save {
  width: 4.75rem !important;
}

.ays-btn-yes {
  width: 4.75rem !important;
  transform: translate(20%, -5%) scale(0.9) !important;
}

.ays-btn-cancel {
  width: 4.75rem !important;
  transform: translate(20%, -5%) scale(0.9) !important;
}

// ------------------------------------------------------------
.section-header {
  // margin-bottom: 2em;

  h4 {
    font-size: 1.1em;
    margin-bottom: 0.8em;
    padding-bottom: 0.8em;
  }

  display: flex;
  align-items: center;
  padding-top: 20px;

  h4 {
    // border-bottom: 1px solid #4648553a;
    font-size: 1.4em;
    margin: 0;
    padding: 0;
    flex: 1;
  }

  h1 {
    font-size: 1.2em;
  }

  .button-containers {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .btn {
      max-width: 10em;
      margin-left: 0.25em;
      margin-right: 0.25em;
    }
  }
}

.sub-section-header {
  // margin-bottom: 2em;

  h1 {
    font-size: 1.2em;
  }

  display: flex;
  align-items: center;
  padding-top: 20px;
}

.seperator {
  padding-bottom: 1.5em;
  margin-bottom: 1em;
  border-bottom: 1px solid #4648553a;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #16395b;
  border-radius: 50%;
  display: inline-block;
}

.action-panel-circle {
  height: 50px;
  width: 50px;
  background-color: #f1f3f9;
  border-radius: 50%;
  // display: inline-block;
}

.deal-stage-dot {
  height: 12px;
  width: 12px;
  background-color: #16395b;
  border-radius: 50%;
  display: inline-block;
}

.bubble-text {
  background: #139ee0;
  color: #fff;
  padding: 7px;
  border-radius: 3px;
  width: 250px;

  p {
    display: inline;
    margin-bottom: 0 !important;
    font-size: 0.8em;
  }

  img {
    width: 13px !important;
    margin-left: 0 !important;
    margin-bottom: 0 !important;
  }
}

.bubble-text::after {
  display: flex;
  content: "";
  border-left: 15px solid transparent !important;
  border-right: 15px solid transparent !important;
  border-top: 15px solid #139ee0 !important;
  position: absolute;
  left: 16px;
}

// ------------------------------------------------------------
label {
  color: $text-blue;
}

// ------------------------------------------------------------
// REACT TABLE
.-headerGroups {
  display: none !important;
}

.-header {
  box-shadow: none !important;
}

.ReactTable {
  border: none !important;
  color: #4d4f5c;
  font-family: "Gotham-Medium";
  .rt-th,
  .rt-td {
    border: none !important;
    position: relative;
  }

  .rt-th {
    background-color: #f1f3f9;

    .rt-resizable-header-content {
      text-align: left !important;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  .rt-td {
    display: flex;
    // justify-content: center;
    align-items: center;

    .img-text {
      display: flex;
      align-items: center;

      p {
        font-weight: bold;
        margin: 0;
        padding-left: 0.5em;
        align-items: center;
      }

      img {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }
    }
  }

  .rt-tr-group {
    border: none !important;
    box-shadow: none !important;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

// ------------------------------------------------------------
.performance-card {
  .header {
    display: flex;
    // justify-content: center;
    align-items: center;

    h4 {
      flex: 1;
      color: $text-blue !important;
      text-align: center;
      font-size: 1.2 em;
    }
  }

  .content {
    display: flex;
    align-items: center;
    color: #4d4f5c;
    margin-top: 0.5em;

    .name {
      font-size: 0.8em;
      flex: 1;
    }

    .value {
      font-size: 0.8em;
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }

    .red {
      color: $red;
    }

    .green {
      color: $green;
    }
  }
}

.over-view-content {
  .total {
    h3 {
      font-size: 0.8em;
      text-align: left;
    }
  }

  .price {
    h4 {
      font-size: 1.8em;
      color: #000000 !important;
      font-weight: normal;
    }
  }

  p,
  span {
    font-size: 0.8em;
    color: #4d4f5c !important;
    margin: 0;
  }

  .growth {
    color: $red !important;
    margin-right: 0.5em;
  }

  .visits {
    // padding-left: 15px;
    padding-top: 10px;

    .title {
      h3 {
        font-size: 0.8em;
        text-align: left;
      }
    }

    .name {
      h4 {
        font-size: 2em;
        color: #000000 !important;
        font-weight: normal;
        margin-bottom: 0rem;
      }
    }

    .presentage {
      display: inline;
      text-align: right;

      h4 {
        font-size: 2.5em;
        color: #000000 !important;
        font-weight: normal;
      }
    }
  }
}

.datacard {
  display: flex;
  flex-direction: row;

  .place {
    padding-top: 15px;
    padding-right: 10px;

    h4 {
      font-size: 3em;
      color: #000000 !important;
      font-weight: normal;
      margin-bottom: 0rem !important;
    }

    sup {
      top: -20px;
      font-size: 0.5em;
      color: #000000 !important;
      margin: 0;
    }
  }

  .cardBg {
    flex: 1;
    height: 70px;
    background-color: #ebebeb;
    border-top-left-radius: 50px 50px;
    border-bottom-left-radius: 50px 50px;
  }

  .namepad {
    padding-top: 13px;
    padding-left: 10px;
  }

  .name {
    h4 {
      font-size: 3em;
      font-weight: normal;
      margin-bottom: 0rem;
    }

    points {
      p,
      span {
        font-size: 0.8em;
        color: #4d4f5c !important;
        margin: 0;
        margin-bottom: 0rem;
      }
    }
  }

  .dp {
    img {
      width: 70px;
      border-radius: 50%;
    }
  }

  .badge {
    padding-left: 170px;
    padding-top: 10px;

    img {
      width: 50px;
      border-radius: 50%;
    }
  }

  //name points dp badge
}

.sales-group-details {
  h1 {
    font-size: 1.5em;
  }

  p {
    margin: 0;
    font-size: 1.1em;
  }

  .title {
    margin-top: 1em;
    font-size: 1em;
  }

  .button-wrapper {
    margin-top: 1em;
  }
}

// ------------------------------------------------------

.profile-header {
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;

  .name-picture {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    img {
      width: 80px;
      border-radius: 50%;
    }

    h1 {
      font-size: 1.8em;
      padding-left: 0.5em;
    }
  }

  .auxilary-btns {
    flex: 2;

    .btn {
      margin-right: 1em;
    }
  }
}

//  -------------------------------------

.collect-section {
  margin-top: 0px;
  text-align: center;

  div {
    font-size: 1.2em;
    padding-top: 1em;
    // padding-bottom: 1em;
  }
}

//  -------------------------------------

.layout-1 {
  display: flex;
  align-items: stretch;

  img {
    width: 60px;
    border-radius: 50%;
  }

  h3 {
    font-size: 1.5em !important;
    font-weight: bold;
    margin-top: 0.5em;
    text-align: center;
  }

  h4 {
    font-size: 1.3em;
    padding: 0;
    margin: 0;
  }

  p {
    margin: 0;
  }

  .right-item {
    flex: 5 !important;
  }

  .left-item {
    flex: 2 !important;
    padding-right: 1em;
    text-align: center;
  }
}

// *---------------------------------------------

.home-overview {
  .card {
    background-image: linear-gradient(to left, #4c57b8, #740130);
    border: none;
  }

  h3,
  h4 {
    color: $white !important;
    text-align: center;
  }

  h3 {
    font-size: 0.8em;
  }

  h4 {
    font-size: 2em;
  }

  .Visits {
    h4 {
      color: $white !important;
      font-size: 2em;
    }

    h3 {
      font-size: 0.8em;
    }
  }
}

//  ------------------------------------------

.visit-details {
  .visit-details-summary {
    h4 {
      font-size: 1.5em;
    }

    p {
      margin: 0;
    }

    .agent {
      display: flex;
      // justify-content: center;
      align-items: center;

      h2 {
        font-size: 1.5em;
        margin-left: 0.5em;
      }

      img {
        width: 50px;
        border-radius: 50%;
      }
    }
  }

  .stats {
    .card {
      background-color: $red !important;
    }

    h3,
    p {
      margin: 0;
      color: $white !important;
    }

    h3 {
      font-size: 1.8em;
    }
  }
}

.route-creation {
  .visit-list-item {
    display: flex;
    justify-content: center;

    .main {
      margin: 0;
      background-color: $off-white;
      text-align: center;
      // margin: 0;
      padding: 0.2em;
      padding-left: 0.7em;
      padding-right: 0.7em;
      color: $text-blue;
      font-weight: bold;
      // background: red;
      box-shadow: 0px 1px 2px 1px rgba(62, 57, 107, 0.1) !important;
      border-radius: 0.8em !important;
      border-color: red !important;
      outline: none !important;

      &:hover {
        box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.07) !important;
      }
    }

    .adder {
      font-size: 1.1em;
      border-radius: 50% !important;
      cursor: pointer;
      margin-bottom: 1em;
    }
  }

  .line-container {
    .line {
      width: 50%;
      border-right: 1px solid $text-blue;
      height: 20px;
    }
  }
}

.cell-alignment {
  white-space: normal !important;
  text-align: left !important;
}

.cell-center {
  justify-content: center !important;
}

.quotation-btn {
  width: 80% !important;

  .customer {
    margin-top: 2px;
  }
}

.upld-quotation-btn {
  position: absolute;
  right: 0;
  bottom: 0;
}

.nav-sub-modal {
  position: absolute;
  left: 232px;
  z-index: 100;
  width: 150px;
  top: 0px;
}

.nav-sub-curve {
  border-radius: 0.8em !important;
  height: 50px;
}

.deal-profile {
  .details-container {
    max-width: 340px;
  }

  .deal-details {
    .title {
      position: relative;

      h1 {
        font-weight: 550;
        font-size: 1.4em;
        font-family: "Gotham-Medium", sans-serif;
      }

      img {
        width: 20px;
        margin-left: 11px;
        margin-bottom: 7px;
      }

      .info-bubble {
        display: flex;
        position: absolute;
        bottom: 35px;
        left: 180px;
        z-index: 1000;
      }

      .info-popup {
        img {
          width: 14px;
          margin-left: 0;
          padding-left: 2px;
        }
      }

      .edit {
        img {
          width: 15px;
        }
      }

      .edit-fields {
        display: flex;
        flex-direction: row;
      }
    }

    .info {
      padding-top: 12px;
      padding-bottom: 10px;
      position: relative;

      h1 {
        font-weight: lighter;
        font-size: 1.1em;
        margin: 0;
        font-family: "Gotham-Book", sans-serif;
      }

      img {
        width: 13px;
        margin-left: 10px;
      }
      .calendar-icon {
        img {
          margin-bottom: 4px;
        }
      }
      .update-close-date {
        display: flex;
        position: absolute;
        top: 20px;
        left: 210px;
        z-index: 10;
        background-color: white;
        border-radius: 1em;

        button {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          border-bottom-left-radius: 0px;
          border-top-left-radius: 0px;
        }
      }

      .core-info {
        display: flex;
        flex-direction: row;

        .info-container {
          position: relative;

          .ficon-container {
            margin-left: 5px;
            width: 20px;
            display: flex;
            justify-content: center !important;
            align-items: center;
            height: 20px;

            img {
              margin: 0;
              width: 12px;
            }
          }

          .drop-down {
            position: absolute;
            right: -165px;
            top: 10px;
            background-color: $white;
            width: 160px;
            z-index: 1;

            ul {
              list-style-type: none;
              margin: 0;
              overflow-y: scroll;
              max-height: 200px;

              li {
                padding-left: 1em;
                padding-top: 0.25em;
                padding-bottom: 0.25em;
                color: black;

                &:hover {
                  background-color: #e3e3e4;
                }
              }
            }
          }
        }
      }
    }

    .other-info {
      // padding-top: 10px;
      .social-info,
      .location-info,
      .target-info {
        display: flex;
        flex-direction: row;

        // justify-content: center;
        img {
          width: 10px;
        }

        h1 {
          margin-left: 5px;
          font-weight: lighter;
          font-family: "Gotham-Book", sans-serif;
        }

        .info-container {
          position: relative;
          margin-left: 15px;

          .drop-down {
            position: absolute;
            left: 20px;
            top: 11px;
            background-color: $white;
            width: 160px;
            z-index: 1;

            ul {
              list-style-type: none;
              margin: 0;
              overflow-y: scroll;
              max-height: 200px;

              li {
                padding-left: 1em;
                padding-top: 0.25em;
                padding-bottom: 0.25em;
                color: black;

                &:hover {
                  background-color: #e3e3e4;
                }
              }
            }
          }
        }
      }
    }
  }

  .action-panel {
    .title {
      padding-bottom: 8px;

      h1 {
        font-weight: 550;
        font-size: 1.2em;
        font-family: "Gotham-Book", sans-serif;
      }
    }

    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      img {
        width: 20px;
      }

      h1 {
        font-size: 0.9em;
        font-weight: lighter;
        font-family: "Gotham-Book", sans-serif;
      }
      .task-icon {
        img {
          width: 19px;
          margin-left: 15px;
          margin-top: 12px;
        }
      }
      .note-icon {
        img {
          margin-left: 15px;
          margin-top: 16px;
        }
      }
      .meeting-icon {
        img {
          margin-left: 15px;
          margin-top: 13px;
        }
      }
      .document-icon {
        img {
          margin-left: 15px;
          margin-top: 15px;
        }
      }
    }
  }

  .customer-info {
    .title {
      display: flex;
      align-items: center;

      h1 {
        font-weight: 550;
        font-size: 1.2em;
        font-family: "Gotham-Book", sans-serif;
      }

      .edit-customer {
        img {
          margin-bottom: 2px;
          margin-left: 8px;
          width: 14px;
        }
      }
      .change-customer {
        img {
          // margin-top: 5px;
          margin-left: 8px;
          width: 22px;
        }
      }
    }

    .info {
      padding-top: 12px;
      padding-bottom: 10px;
      padding-left: 10px;

      h1 {
        font-weight: lighter;
        font-size: 1.1em;
        margin: 0;
        font-family: "Gotham-Book", sans-serif;
      }
    }
  }

  .profile-nav {
    .list-items {
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      li {
        float: left;
      }

      li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
      }

      li a:hover {
        background-color: $maroon-dark;
      }

      .nav-link {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 1.1em;
        padding: 0.5em;
        width: 100px;
        height: 43px;
        border-bottom: #b3b3b3 solid 1px;

        &:hover {
          border-bottom: $maroon solid 2px;
          font-weight: bold;
          font-size: 1.15em;
        }
      }

      .active-class {
        border-bottom: $maroon solid 2px;
        font-weight: bold;
        font-size: 1.15em;
      }
    }
  }

  .tab-content {
    .content-title {
      h1 {
        font-size: 1.3em;
        font-weight: 500;
        font-family: "Gotham-Book", sans-serif;
      }
    }
  }

  .new-meeting {
    margin-top: 1.5rem;

    .btn-container {
      position: absolute;
      top: 15px;
      left: 0px;
    }
  }

  .new-task {
    margin-top: 1.5rem;

    .btn-container {
      position: absolute;
      top: 15px;
      left: 0px;
    }
  }

  .activities {
    .upcoming-item {
      padding-left: 15px;

      .activity {
        display: flex;
        flex-direction: row;
        margin-top: 3px;
        margin-bottom: 3px;

        img {
          width: 19px;
          margin-right: 5px;
        }

        .info {
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          align-items: flex-start;

          h1 {
            // font-size: 1.1em;
            font-weight: 500;
            font-family: "Gotham-Medium", sans-serif;
          }

          h3 {
            font-size: 0.8em;
            font-weight: lighter;
            font-family: "Gotham-Book", sans-serif;
          }
        }
      }

      .drop-details {
        img {
          width: 10px;
          float: right;
          margin-right: 20px;
          margin-top: 5px;
        }
      }

      .due-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        h1 {
          font-family: "Gotham-Book", sans-serif;
          font-size: 0.8em;
          font-weight: 500;
        }

        .note {
          h2 {
            font-size: 0.8em;
            font-family: "Gotham-Book", sans-serif !important;
          }
        }

        img {
          width: 10px;
          float: right;
          margin-right: 20px;
          margin-top: 5px;
        }
        .expand-by-text {
          h1 {
            margin-top: 3px;
          }
          u {
            font-family: "Gotham-Book", sans-serif;
            font-weight: 500;
            font-size: 1em;
            cursor: pointer;
          }
        }
      }
    }

    .bottom-seperator {
      margin-left: 22px;
      padding-bottom: 0.5em;
      margin-bottom: 0;
      border-bottom: 1px solid #4648553a;
    }

    .top-seperator {
      margin-left: 22px;
      padding-top: 0.5em;
      margin-top: 0.5em;
      border-top: 1px solid #4648553a;
    }
    .unavaiable-content {
      margin-inline-start: 20px;
      h1 {
        font-family: "Gotham-Medium", sans-serif;
        font-weight: 500;
      }
    }
  }

  .conversation {
    .comment-row {
      margin-right: 3px;
    }

    .text-area-div {
      margin: 0;
      padding: 0;
      margin-bottom: 0;
    }

    .comment-text-area {
      box-shadow: none;
      resize: none;
      border-radius: 0;
      border-bottom-left-radius: 4px;
      border-top-left-radius: 4px;
      font-family: "Gotham-Book", sans-serif;
      font-size: 0.9em;
      background-color: white;
    }

    .comment-div {
      padding-right: 0;
      padding-bottom: 0;
    }

    .comment-btn-div {
      // padding: 0;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
    }

    .comment-btn-container {
      padding-right: 0;
    }

    .comment-submit-btn {
      margin-left: 0 !important;
    }

    .new-post {
      margin-top: 1.5rem;
      .submit-btn {
        max-width: none;
        padding: 0;
      }
    }

    .likes-and-comments {
      margin-bottom: 15px;

      h1 {
        font-weight: lighter;
      }

      .label {
        display: flex;
        flex-direction: row;
      }

      .actions {
        display: flex;
        flex-direction: row;
        margin-top: 5px;

        .like-section {
          display: flex;
          flex-direction: row;

          img {
            width: 15px;
          }

          .like-label {
            margin-inline-start: 10px;
            margin-top: 2px;
          }
        }

        .answer-section {
          display: flex;
          flex-direction: row;
          margin-left: 15px;
          margin-top: 2px;

          img {
            width: 15px;
          }

          .answer-label {
            margin-inline-start: 10px;
          }
        }
      }
    }
    .unavaiable-content {
      h1 {
        font-family: "Gotham-Medium", sans-serif;
        font-weight: 500;
      }
    }
    .single-conversation {
      padding-inline-start: 0;
      padding-inline-end: 0;
      padding-top: 10px;
      padding-bottom: 0;
      margin-top: 30px;
      box-shadow: 0px 4px 2px #00000007;

      .profile-header {
        padding-bottom: 0;
        margin-bottom: 5px;

        .name-picture {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 40px;
            border-radius: 50%;
          }

          .details {
            flex-direction: column;

            h1 {
              font-size: 1.1em;
              font-family: "Gotham-Bold", sans-serif;
            }

            h2 {
              padding-left: 10px;
              font-size: 0.8em;
              font-weight: 500;
              font-family: "Gotham-Medium", sans-serif;
            }
          }
        }

        .right-container {
          h1 {
            color: #bec4cb !important;
            font-weight: 500;
            font-size: 0.8em;
            font-family: "Gotham-Book", sans-serif;
          }
        }
      }

      .post {
        padding-bottom: 20px;

        h1 {
          font-weight: 500;
          font-size: 1.1em;
          font-family: "Gotham-Book", sans-serif;
        }
      }

      .comment {
        margin-top: 20px !important;
      }
    }

    .comment-section {
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 15px;
      background-color: #f7f8fb;
      width: 100%;

      .profile-header {
        padding-bottom: 0;
        margin-bottom: 5px;

        .name-picture {
          flex: 1;
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 40px;
            border-radius: 50%;
          }

          .details {
            flex-direction: column;

            h1 {
              font-size: 1.1em;
              font-family: "Gotham-Bold", sans-serif;
            }

            h2 {
              padding-left: 10px;
              font-size: 0.8em;
              font-weight: 500;
              font-family: "Gotham-Medium", sans-serif;
            }
          }
        }
      }

      .comment {
        margin-top: 20px !important;
      }

      .likes-and-comments {
        margin-bottom: 15px;

        h1 {
          font-weight: lighter;
          font-size: 0.9em;
          font-family: "Gotham-Book", sans-serif;
        }

        .label {
          display: flex;
          flex-direction: row;
        }

        .actions {
          display: flex;
          flex-direction: row;
          margin-top: 5px;

          .like-section {
            display: flex;
            flex-direction: row;

            img {
              width: 15px;
            }

            .like-label {
              margin-inline-start: 10px;
              margin-top: 2px;
              font-family: "Gotham-Book", sans-serif;
            }
          }

          .answer-section {
            display: flex;
            flex-direction: row;
            margin-left: 15px;
            margin-top: 2px;

            img {
              width: 15px;
            }

            .answer-label {
              margin-inline-start: 10px;
            }
          }
        }

        .dot-seperator {
          margin-top: 10px;
          margin-left: 12px;
          margin-right: 12px;
        }

        .labels {
          display: flex;
          flex-direction: row;

          h1,
          label {
            // font-size: 0.9rem;
            font-weight: lighter;
            font-family: "Gotham-Book", sans-serif;
          }
        }
      }

      .submit-comment {
        margin-bottom: 15px;
      }

      .previous-comment {
        margin-top: 10px;

        .posted-comment {
          margin-left: 45px;

          h1 {
            font-weight: lighter;
            font-family: "Gotham-Book", sans-serif;
          }

          .submit-reply {
            margin-bottom: 10px;
          }

          .reply-section {
            margin-bottom: 8px;
          }

          .comment-replies {
            margin-top: 25px !important;
            h1 {
              font-size: 1.1em;
              font-weight: bold;
              font-family: "Gotham-Bold", sans-serif;
            }
            .since-val {
              h1 {
                font-family: "Gotham-Book", sans-serif;
                font-size: 0.8em;
                font-weight: lighter;
              }
            }
            .reply {
              h1 {
                font-weight: lighter;
                font-size: 0.9em;
                font-family: "Gotham-Book", sans-serif;
              }
              margin-left: 45px;

              .like-section {
                display: flex;
                flex-direction: row;
                h1 {
                  font-size: 0.9em;
                }
                .like-label {
                  margin-inline-start: 10px;
                  margin-top: 3px;
                }

                .labels {
                  h1 {
                    margin-top: 3px;
                  }
                }
              }

              .dot-seperator {
                margin-top: 11px;
                margin-left: 12px;
                margin-right: 12px;
              }
            }
          }
        }
      }
    }
  }

  .docs-panel {
    margin-top: 1.5rem;
    .toggle-button {
      box-shadow: 2px 2px 3px #565a7538 !important;
      outline: none !important;
      border: none !important;
      border-radius: 0.35rem;
      width: 150px;
      padding: 15px;
      margin-bottom: 10px;
      background-color: white;
    }
    .active {
      background-color: $maroon !important;
      border-color: $maroon !important;
      color: white !important;
    }

    .glance {
      background: white;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
      padding: 40px;
      margin-top: 50px;

      .summary {
        margin-top: 30px;

        .action {
          cursor: pointer;
          text-decoration: underline;
          color: $maroon;
          font-weight: bold;
        }

        .single-comment {
          background: #f7f8fb 0% 0% no-repeat padding-box;
          box-shadow: 0px 3px 6px #e6eaf27b;
          border-radius: 10px;
          opacity: 1;
          padding: 20px;
          margin-bottom: 30px;
        }

        .top-section {
          display: flex;
          .action {
            text-align: left;
            font: normal normal 300 10px/14px;
            color: #4d4f5c;
            opacity: 1;
            flex-grow: 1;
          }
          .timestamp {
            text-align: left;
            font: normal normal 300 10px/14px;
            color: #4d4f5c;
            opacity: 1;
          }
        }
        .middle-section {
          display: flex;
          justify-content: center;
          margin-top: 5px;
          .comment-text {
            flex-grow: 1;
            text-align: left;
            color: #172b4d;
            font-weight: bold;
            font-size: 15px;
            opacity: 1;
          }
          .view-comment {
            flex-shrink: 0;
            text-decoration: underline;
            color: #4b9598;
            opacity: 1;
            cursor: pointer;
          }
        }
        .bottom-section {
          display: flex;
          margin-top: 10px;
          width: 100%;
          .form {
            display: flex;
            width: 100%;
            .comment-input {
              flex-grow: 1;
              border: 1px solid #dce0e3;
              border-radius: 4px 0px 0px 4px;
              padding: 4px;
              outline: none;
              width: 100%;
            }
            .comment-btn {
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }

      .summary-select {
        font-size: 16px;
      }

      .MuiInput-underline:after {
        border-bottom: 0px !important;
      }
    }

    .add-doc {
      margin-bottom: 25px;
    }

    .unavaiable-content {
      h1 {
        font-family: "Gotham-Medium", sans-serif;
        font-weight: 100;
        margin-top: 50px;
      }
    }

    .path-text {
      font-family: "Gotham-Medium", sans-serif;
      font-weight: 100;
      margin-left: 20px;
    }

    .top-seperator {
      margin-left: 22px;
      padding-top: 0.5em;
      margin-top: 30px;
    }

    .single-file {
      align-items: center;
      color: #212529;
      display: flex;
      flex-basis: 25%;
      flex-direction: column;
      font-size: 14px;
      line-height: 21px;
      margin-top: 20px;
      text-align: left;
      .content {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
        background-color: white;
        width: 300px;
        padding: 30px;
        box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
        border-radius: 0.8em;
        border-color: transparent;
        outline: none;
        cursor: pointer;
        .info {
          margin-top: 5px;
          align-items: flex-start;

          .sub-info {
            font-weight: lighter;
          }
        }
      }
      img {
        width: 60px;
      }
    }

    .document-tag,
    .document-type,
    .approval-label {
      margin-top: 14px;
    }

    .approval-checkboxes {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .folder-icon {
      width: 80px;
    }

    .folder {
      align-items: center;
      color: #212529;
      display: flex;
      flex-basis: 25%;
      flex-direction: column;
      font-size: 14px;
      line-height: 21px;
      margin: 0px 0px 10px;
      text-align: left;
    }
    .folder-card {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      display: block;
      background-color: white;
      width: 300px;
      padding: 30px;
      box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.05);
      border-radius: 0.8em;
      border-color: transparent;
      outline: none;
      cursor: pointer;
    }

    .title {
      font-family: "Gotham-Book", sans-serif;
      font-weight: 700;
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    .bottom-seperator {
      margin-left: 22px;
      padding-top: 0.5em;
      margin-top: 30px;
      padding-bottom: 0.5em;
      margin-bottom: 30px;
      border-bottom: 1px solid #4648553a;
    }
  }
  .context-menu {
    position: absolute;
    background: $maroon-dark;
    box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.01);
    border-radius: 0.4em;
    border-color: transparent;
    outline: none;

    .menu-option {
      font-family: Lato;
      font-size: 14px;
      color: white;
      padding: 10px 40px 10px 40px;
      border-bottom: 1px solid $maroon-dark;
      cursor: pointer;
    }
  }

  .menu-option:hover {
    background: rgb(9, 18, 34);
  }

  .disabled {
    cursor: not-allowed !important;
  }

  .task-panel {
    section {
      margin-top: 0;
      margin-bottom: 0;
    }

    .panel-container {
      margin-top: 1.5em;
    }

    .unavaiable-content {
      margin-inline-start: 20px;
      h1 {
        font-family: "Gotham-Medium", sans-serif;
        font-weight: 500;
      }
    }

    .align-items-horizontal {
      display: flex;
      flex-direction: row;
    }

    .align-items-vertical {
      display: flex;
      flex-direction: column;
    }

    .single-task {
      // background-color: #FFFFFF;
      margin-bottom: 20px;
      border-radius: 3px;
      box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);

      .activity {
        margin-left: 22px;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        margin-bottom: 10px;

        img {
          width: 19px;
          margin-right: 5px;
        }

        .info {
          display: flex;
          flex-direction: column;
          padding-left: 8px;
          align-items: flex-start;
          h1 {
            // font-size: 1.1em;
            font-weight: 500;
            font-family: "Gotham-Medium", sans-serif;
          }
          h3 {
            font-size: 0.8em;
            font-weight: lighter;
            font-family: "Gotham-Book", sans-serif;
          }
          .description {
            align-items: center;
            h1 {
              margin-top: 1px;
            }
          }
        }
      }

      .due-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-top: 10px;
        flex: 1;
        margin-right: 25px;

        h1 {
          font-family: "Gotham-Book", sans-serif;
          font-size: 0.8em;
          font-weight: 500;
        }
        .note {
          h2 {
            font-size: 0.8em;
            font-family: "Gotham-Book", sans-serif !important;
            color: #4e0911;
          }
        }

        .expand-by-text {
          h1 {
            margin-top: 3px;
          }
          u {
            font-family: "Gotham-Book", sans-serif;
            font-weight: 500;
            font-size: 1em;
            cursor: pointer;
          }
        }
      }

      .expanded-container {
        background-color: #f7f8fb;
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;

        .sub-tasks {
          margin-left: 55px;
          margin-top: 15px;

          .sub-item-container {
            margin-top: 8px;
            margin-bottom: 15px;
          }

          .sub-item {
            margin-top: 8px;
            margin-bottom: 8px;
          }

          .sub-item-info {
            margin-top: 7px;
          }

          .check-sub-task {
            position: relative;
            top: 0;
            margin-right: 8px;
            margin-left: 2px;
          }

          .title {
            h1 {
              font-family: "Gotham-Book", sans-serif;
              font-weight: 500;
              font-size: 1em;
            }
          }

          .add-sub-task {
            text-align: right;
            h1 {
              font-family: "Gotham-Medium", sans-serif;
              font-weight: lighter;
              font-size: 0.825em;
              margin-right: 10px;
              color: #e04b5e !important;
              cursor: pointer;
            }
          }

          .subtask-desc {
            h1 {
              margin-top: 1px;
              font-family: "Gotham-Book", sans-serif;
              font-weight: 500;
              font-size: 0.9em;
            }
          }

          .subtask-title {
            h1 {
              font-family: "Gotham-Medium", sans-serif;
              font-size: 0.9375em;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

.deal-main-summary {
  margin-bottom: 40px;

  .filter {
    display: flex;
    flex-direction: row;

    .filter-container {
      position: relative;

      h1 {
        display: inline;
        font-size: 1.5em;
      }

      .filter-icon-container {
        margin-top: 6px;
        margin-left: 25px;
        width: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 20px;

        img {
          width: 15px;
        }
      }

      .drop-down {
        position: absolute;
        right: -165px;
        top: 20px;
        background-color: $white;
        width: 160px;
        z-index: 1;

        ul {
          list-style-type: none;
          margin: 0;
          overflow-y: scroll;
          max-height: 200px;

          li {
            padding-left: 1em;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
            color: red;

            &:hover {
              background-color: #e3e3e4;
            }
          }
        }
      }
    }
  }

  .figures {
    margin-top: 20px;

    .topic {
      h1 {
        font-size: 1.1em;
        font-family: "Gotham-Book", sans-serif;
        font-weight: 200;
      }
    }

    .value {
      h1 {
        font-size: 1.5em;
      }
    }
  }
}

.calendar-border {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.flex {
  display: flex;

  &-1 {
    flex: 1;
  }
}

.flex-row {
  flex-direction: row;
}

.hidden {
  visibility: hidden !important;
}

.container {
  display: flex;
  // max-width: 100%;
  padding-left: 10px;
  border: 1px #8898aa solid;
  border-radius: 5px;
  color: #8898aa;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.tag-container {
  border-radius: 3px;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  // padding: 6px;
}

.container input {
  border: none;
  border-radius: 5px;
  padding: 8px;
  padding-left: 14px;
  outline: none;
  border: 0;
  width: 100%;
  background-color: transparent;
}

.tag {
  display: flex;
  align-items: center;
  margin: 7px 0;
  margin-right: 10px;
  padding: 0 10px;
  padding-right: 5px;
  border: 1px solid $maroon;
  border-radius: 5px;
  background-color: $maroon;
  white-space: nowrap;
  color: white;

  button {
    display: flex;
    padding: 6px;
    border: none;
    background-color: unset;
    cursor: pointer;
    color: white;
  }

  img {
    width: 8px;
    margin-left: 5px;
    margin-bottom: 2px;
  }
}

.deals-main {
  position: relative;
  // overflow: hidden;
  .drop-down {
    position: absolute;
    left: 100px;
    top: 0;
    background-color: $white;
    width: 160px;
    z-index: 100;
    ul {
      list-style-type: none;
      margin: 0;
      overflow-y: scroll;
      text-align: left;
      // max-height: 200px;
      min-height: 200px;
      li {
        padding-left: 1em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        color: black;
        &:hover {
          background-color: #e3e3e4;
        }
      }
    }
  }
}
.more-details-nav {
  .list-items {
    width: 600px;
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    li {
      float: left;
      width: 100%;
    }

    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }

    li a:hover {
      background-color: $maroon-dark;
    }

    .nav-link {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 1.1em;
      padding: 0.5em;
      width: 100px;
      height: 43px;
      border-bottom: #b3b3b3 solid 1px;
      width: 150px;

      &:hover {
        border-bottom: $maroon solid 2px;
        font-weight: bold;
        font-size: 1.15em;
        width: 100%;
      }
    }

    .active-class {
      border-bottom: $maroon solid 2px;
      font-weight: bold;
      font-size: 1.15em;
      width: 100%;
    }
  }
}

.tab-content {
  margin-top: 1rem !important;
  border: none !important;
  color: #4d4f5c;
  font-family: "Gotham-Medium";
}

.history-list {
  height: 300px !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.history-item {
  display: flex;
  flex-direction: row;
  margin-top: 3px;
  margin-bottom: 3px;

  img {
    width: 19px;
    margin-right: 5px;
  }

  .info {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    align-items: flex-start;

    h1 {
      // font-size: 1.1em;
      font-weight: 500;
      font-family: "Gotham-Medium", sans-serif !important;
    }

    h3 {
      font-size: 0.8em;
      font-weight: lighter;
      font-family: "Gotham-Book", sans-serif;
    }
  }
}

.deals-table {
  .ReactTable {
    // .rt-tbody {
    //   overflow: visible !important;
    // }

    // .rt-table {
    //   overflow: visible !important;
    // }
  }
}

.align-items-horizontal {
  display: flex;
  flex-direction: row;
}

.main-leads-label {
  color: $maroon;
  font-size: 1.5rem;
  font-weight: bold;
  margin-left: 10px;
  opacity: 1;
  transition: all 3s ease ;
}

.leads-main {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;

  .cell-container {
    width: 100%;
    text-align: left;
  }

  .cell-dropdown-align {
    img {
      margin-top: 8px;
    }
  }

  .cell-dropdown {
    align-items: center;
    position: relative;
    // z-index: 100 !important;

    img {
      position: relative;
      right: 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    .drop-down {
      position: absolute;
      z-index: 100 !important;
      left: 0;
      top: 43px;
      background-color: $white;
      width: 200px;
      text-align: left;
      overflow: visible !important;
      text-overflow: ellipsis;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: $maroon;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .action-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    img {
      width: 22px;
    }

    .view-more {
      position: relative;

      .drop-down {
        position: absolute;
        right: 0;
        top: 28px;
        background-color: $white;
        width: 150px;
        z-index: 150;
        text-align: left;
        overflow: hidden !important;

        ul {
          list-style-type: none;
          margin: 0;

          li {
            padding-left: 1em;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
            color: black;

            &:hover {
              background-color: #e3e3e4;
            }
          }
        }
      }
    }
  }

  .actions {
    display: flex;
    font-family: $gotham;

    h1 {
      margin-top: 5px;
    }

    .status-update-btn {
      position: relative;
      img {
        margin-left: 12px;
        width: 9px;
      }
    }

    .right-container {
      flex: 1;
      .start-lead-btn {
        padding-right: 30px !important;
        padding-left: 30px !important;
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        font-size: 16px;
        font-weight: normal;
      }
    }

    .left-container {
      display: grid;
      grid-template-columns: 3fr 2fr 2fr 2fr;
      column-gap: 10px;
    }

    .optimze-items {
      display: flex;
      position: relative;

      button {
        min-width: 150px;
        text-align: left;

        img {
          margin-top: 8px;
        }
      }

      .drop-down {
        position: absolute;
        left: 0;
        top: 34px;
        background-color: $white;
        width: 100%;
        z-index: 1;

        ul {
          list-style-type: none;
          margin: 0;

          li {
            padding-left: 1em;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
            color: black;

            &:hover {
              background-color: #e3e3e4;
            }
          }
        }
      }
    }
    .form-category-items {
      display: flex;
      position: relative;
      flex-flow: row-reverse;

      button {
        cursor: pointer;
      }

      .drop-down {
        position: absolute;
        right: 0;
        top: 50px;
        background-color: $white;
        width: 17%;
        z-index: 1;
        cursor: pointer;

        ul {
          list-style-type: none;
          margin: 0;

          li {
            padding-left: 1em;
            padding-top: 0.25em;
            padding-bottom: 0.25em;
            color: black;

            &:hover {
              background-color: #e3e3e4;
            }
          }
        }
      }
    }
  }

  .list-items {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      overflow: hidden;
    }

    li {
      float: left;
    }

    li a {
      display: block;
      color: white;
      text-align: center;
      padding: 16px;
      text-decoration: none;
    }

    li a:hover {
      background-color: $maroon-dark;
    }

    .nav-link {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1.1em;
      padding: 0.5em;
      width: 100px;
      height: 43px;
      border-bottom: #b3b3b3 solid 1px;

      &:hover {
        border-bottom: $maroon solid 2px;
        font-weight: bold;
        font-size: 1.15em;
      }
    }

    .active-class {
      border-bottom: $maroon solid 2px;
      font-weight: bold;
      font-size: 1.15em;
    }
  }

  .more-details-nav {
    .list-items {
      width: 600px;
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        overflow: hidden;
      }

      li {
        float: left;
        width: 100%;
      }

      li a {
        display: block;
        color: white;
        text-align: center;
        padding: 16px;
        text-decoration: none;
      }

      li a:hover {
        background-color: $maroon-dark;
      }

      .nav-link {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1.1em;
        padding: 0.5em;
        width: 100px;
        height: 43px;
        border-bottom: #b3b3b3 solid 1px;
        width: 150px;

        &:hover {
          border-bottom: $maroon solid 2px;
          font-weight: bold;
          font-size: 1.15em;
          width: 100%;
        }
      }

      .active-class {
        border-bottom: $maroon solid 2px;
        font-weight: bold;
        font-size: 1.15em;
        width: 100%;
      }
    }
  }

  .tab-content {
    margin-top: 1rem !important;
  }

  .history-list {
    height: 300px !important;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .history-item {
    display: flex;
    flex-direction: row;
    margin-top: 3px;
    margin-bottom: 3px;

    img {
      width: 19px;
      margin-right: 5px;
    }

    .info {
      display: flex;
      flex-direction: column;
      padding-left: 8px;
      align-items: flex-start;

      h1 {
        // font-size: 1.1em;
        font-weight: 500;
        font-family: "Gotham-Medium", sans-serif;
      }

      h3 {
        font-size: 0.8em;
        font-weight: lighter;
        font-family: "Gotham-Book", sans-serif;
      }
    }
  }

  .event-time {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 28px;

    h1 {
      font-family: "Gotham-Book", sans-serif;
      font-size: 0.8em;
      font-weight: 500;
    }
  }

  .unavaiable-content {
    h1 {
      font-family: "Gotham-Medium", sans-serif;
      font-weight: 500;
    }
  }

  .bottom-seperator {
    margin-left: 22px;
    padding-bottom: 0.5em;
    margin-bottom: 0;
    border-bottom: 1px solid #4648553a;
  }

  .top-seperator {
    margin-left: 22px;
    padding-top: 0.5em;
    margin-top: 0.5em;
    border-top: 1px solid #4648553a;
  }

  .unavaiable-content-leads {
    h1 {
      font-family: "Gotham-Medium", sans-serif;
      font-weight: 500;
    }
  }
}

.leads-more-field-title {
  h1 {
    max-width: 10px;
  }
}

.flex-none {
  flex: none !important;
}

.deals-main-actions {
  display: flex;

  h1 {
    margin-top: 5px;
  }

  .status-update-btn {
    position: relative;
    img {
      margin-left: 12px;
      width: 9px;
    }
  }

  .right-container {
    flex: 1;
  }

  .optimze-items {
    display: flex;
    position: relative;

    button {
      min-width: 150px;
      text-align: left;

      img {
        margin-top: 8px;
      }
    }

    .drop-down {
      position: absolute;
      left: 0;
      top: 34px;
      background-color: $white;
      width: 100%;
      z-index: 1;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: black;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }

  .deal-view-as {
    display: flex;
    position: relative;
    

    button {
      min-width: 150px;
      text-align: left;

      img {
        margin-top: 8px;
      }
    }

    .drop-down {
      position: absolute;
      left: 0;
      top: 34px;
      background-color: $white;
      width: 100%;
      z-index: 1;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: black;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }
  }
}

.deals-stage-cell {
  align-items: center;

  img {
    position: absolute;
    // right: 0;
    margin-right: 10px;
    margin-top: 8px;
  }

  // .drop-down {
  //   position: absolute;
  //   left: 0;
  //   top: 43px;
  //   background-color: $white;
  //   width: 100%;
  //   z-index: 100;
  //   text-align: left;
  //   height: 200px;
  //   overflow-y: scroll !important;

  //   ul {
  //     list-style-type: none;
  //     margin: 0;

  //     li {
  //       padding-left: 1em;
  //       padding-top: 0.25em;
  //       padding-bottom: 0.25em;
  //       color: black;

  //       &:hover {
  //         background-color: #e3e3e4;
  //       }
  //     }
  //   }
  // }

  &:hover {
    cursor: pointer;
  }
}

.date-filter-options {
  position: absolute;
  left: 0;
  top: 33px;
  background-color: $white;
  width: 100%;
  z-index: 100;
  text-align: left;

  ul {
    list-style-type: none;
    margin: 0;

    li {
      padding-left: 1em;
      padding-top: 0.25em;
      padding-bottom: 0.25em;
      color: black;

      &:hover {
        background-color: #e3e3e4;
        cursor: pointer;
      }
    }
  }
}

.time-slot-calendar {
  // background-color: red;
  .react-calendar {
    min-width: 250px;
    width: 100%;
    background: transparent;
    border: none;
  }

  .react-calendar__month-view__weekdays__weekday {
    * {
      text-decoration: none !important;
      color: gray;
    }
  }

  .react-calendar__month-view__days__day,
  .react-calendar__navigation__arrow,
  .react-calendar__navigation__label {
    color: $maroon;
    font-weight: bold;
  }

  .react-calendar__navigation__arrow {
    font-size: 1.5rem;
  }

  .react-calendar__navigation__label__labelText {
    font-size: 1.2rem;
  }

  .react-calendar__month-view__days__day--neighboringMonth {
    color: gray;
  }
}

.time-slot-view-as {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.meeting-time-slot {
  padding: 10px;
}

.global-modal-closer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
}

.date-range-checkbox {
  h1 {
    margin-top: 10px;
    font-weight: normal;
    font-size: 0.9rem;
  }
}

.add-option {
  margin-top: 2em;
}

.isCompulsory {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.country-code-warning-message {
  width:fit-content small {
    width: 100%;
  }
}

