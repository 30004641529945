.leave-summary {
  color: #172B4D ;
  font-size: 2em;
  strong {
    color: #172B4D;
    font-size: 1.5em;
  }

  .outstanding {
    text-align: justify;
    text-align-last: left;
    margin-top: 2.1em;
    h1 {
      margin: 0;
      font-size: 2em;
      color:#172B4D ;
      .currency{
        margin-left: 0.5em;
      }
    }
  }

  .leaveanalysis-card{
    padding-bottom: 1.1em;
    padding-top: 0.4em;
    line-height: 1.8em;
    .title{
      font-weight: bolder;
    }
    h1 {
      margin: 0;
      font-size: 2em;
      color: #000000;
      .currency{
        margin-left: 0.5em;
      }
    }
  }

  .sickLeave {
    background-color: #ffffff;
  }
  .casualLeave {
    background-color: #ffffff;
  }
  .noPay {
    background-color: #ffffff;
  }
  .awaitingProof {
    background-color: #ffffff;
  }
}
