

.react-table {
    border:#A0A0FF 0% 0% !important;
    box-shadow: 0 0 1px #4D4F5C;
    border-radius: 10px 10px 0 0 !important;
    width: 100% !important;
    color: $maroon;
    opacity: 1;
    font-family:$gotham;
    background-color: #FFFFFF;
    overflow-x: scroll;
    height: 100% !important;
    margin-bottom: 120px;

    td {
      background: white;
      padding-left: 20px;
    }
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: transparent;
  // margin-left: 612px;
  overflow: hidden;
}

::-webkit-scrollbar-thumb {
  background: $maroon !important;
  border-radius: 50px;
}

// scrollbar for Firefox

.scroller {
  width: 30%;
  height: 100px;
  scrollbar-color: $maroon transparent;
  // border-radius: 50px !important;
  // border: 1px solid red;

  display: inline-block;
}
.scroll-width-thin {
  scrollbar-width: thin;
}
.scroll-direction {
  overflow-x: scroll;
}

.headers {
    background:  #F1F3F9 0% 0% no-repeat padding-box;
    height: 54px;
    font-family:$gotham-medium;
    text-align: left;
    padding-top: 15px;
    padding-left: 26px;
    // width: 200px;

    }

.status-header {
  background:  #F1F3F9 0% 0% no-repeat padding-box;
  height: 54px;
  font-family:$gotham-medium;
  text-align: left;
  padding-top: 15px;
  border-right: 1px solid #dfe4f1;
  padding-left: 26px;
  // width: 200px;

}

.rows {
  padding-top: 10px;
  padding-bottom: 10px;
  height: 50px;
  border-bottom: 1px solid #dfe4f1;
}

.status-rows {
  padding-top: 9px;
  padding-bottom: 9px;
  height: 50px;
  border-bottom: 1px solid #dfe4f1;
  border-right: 1px solid #dfe4f1;
}

// .selection-column {
//   width: 50px;
// }

// .status-column {
//   width: 200px !important;
// }

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
  accent-color: $maroon !important; //new property
  height: 16px;
  width: 16px;
  margin-left: 10px;
  margin-top: 5px;
  padding-top: 10px;
}

// actions bar

.action-bar {
  display: flex;
  color: $maroon;
  margin-left: 30px;
  margin-bottom: 35px;
  vertical-align: top;
  width: 100%;
  // position: absolute;

  .labled-input {
    background: transparent !important;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: 2px solid #00000007;
    width: 200px;
    opacity: 1;
    color: $maroon !important;
    margin-left: 15px;

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $maroon !important;
  }
  :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: $maroon !important;
     opacity:  1;
  }
  ::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: $maroon !important;
     opacity:  1;
  }
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $maroon !important;
  }
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: $maroon !important;
  }

  ::placeholder { /* Most modern browsers support this now. */
    color: $maroon !important;
  }
    &:focus {
     outline: none !important;
    }

    .search-img {
      width: 13px;
      margin-left: 0.7rem;
      margin-right: 0.7rem;
      border-bottom: 1px solid #00000007;
    }
  }

  .all-rows{
    height: 16px;
    width: 16px;
    margin-top: 5px !important;
    accent-color: $maroon !important;
    margin-left: 5px;


  }
  .action-bar-in {
    // display: flex;
    // justify-content: space-between;
    // color: $maroon;
    vertical-align: top;
    width: 100%;
    position: relative;
    margin-right: 50px;

    .interaction-btns {
      height: 30px;
      width: 30px;
      border: 1px solid $maroon;
      border-radius: 5px;

      img {
        padding-left: 6px;
        padding-top: 2px;
      }
    }
.btn-wrapper {
  display: flex;
  justify-content: center;
  .schedule-btns {
    border: 1px solid $maroon;
    border-radius: 5px;
    background: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;

  }
  .delete-btn {
    border: 1px solid #DE412E;;
    border-radius: 5px;
    background: transparent;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    color:#DE412E;
  }
}
  }


  // .action-bar-left {
  //   display: grid;
  //   grid-template-columns: 1fr 4fr;
  //   column-gap: 20px;

  // }

  .action-bar-right {
    display: flex;
    justify-content: right;
    margin: 0 auto;

    label {
      padding-right: 10px;
    }
  }

  .box {
    height: 16px;
    width: 16px;
    margin-top: 5px !important;
    background-color:red !important;
  }
  .drop-down {
    position: absolute;
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    width: 200px;
    height: 200px;
    z-index: 100;
    right: 4px;
    left: auto;
    margin-right: 30px;
    padding-top: 0.25em;
    display: -webkit-box;


    // left: 0;
    // bottom: 0;
    ul {
      list-style-type: none;
      list-style: none;
      margin: 0;
      overflow-y: scroll;

      li {
        padding-left: 0.25em;
        padding-top: 0.25em;
        padding-bottom: 0.25em;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
.check-dropdown {
  input[type="checkbox"] {
    box-sizing: border-box;
    accent-color: $maroon !important; //new property
    height: 16px;
    width: 16px;

  }
  ul li {
    list-style: none;
  }
   label {
     margin-left: 5px !important;
   }
}

  .all-rows:checked:indeterminate {
    .box {
      background-color: $maroon !important ;
    }
  }

  .selected-label {
    padding-left: 20px;
    margin-top: 2px;
  }
}

.bottom-actions {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 1000;
    background-color: #F1F3F9;
    // margin-top: 50px; 
    padding-top: 30px;
    border-top: 1px solid #dfe4f1;
    opacity: 1;
    transition: opacity 1.8s ease-out 1s;

    .not-selected {
      display:grid;
      grid-template-columns: repeat(8, 1fr);
      // column-gap: 2px;

      // padding-top: 30px !important;
    }
    .when-selected {
      display:grid;
      grid-template-columns: repeat(4, 1fr);
      //column-gap: 5px;
      // padding-top: 30px !important;
    }

    .scroll-more {
    float: right;
    background-color: #F1F3F9;
    border: 1px solid #4D4F5C;
    align-items: center;
    padding-bottom: 0px;
      legend {
        font-family:$gotham;
        color: $maroon;
        font-size: 10px;
      }
    }
    .row-control {
      padding-top: 8px;
      float: right !important;
      background: transparent;
      outline: none;
      border: none;
      color:  $maroon !important;
      // right: 0;
      margin-right: 60px;
      margin-bottom: 50px !important;
    }
    .pagination {
      // float: right !important;
     margin-left: 60px !important;
     margin-bottom: 50px !important;
      .pagination-btns {
        // padding-top: 8px;
        cursor: pointer;
        background: transparent;
        border: none;
        outline: none !important;
        color: #4D4F5C !important;
        padding-left: 10px;
        padding-right: 20px;
        // right: 0;
        font-size: 14px;
        vertical-align: top;
      }
      .pagination-btns:active {
        color: #4B9598 !important;
      }

      .pagination-btns:hover {
        color: #4B9598 !important;
      }
      .pagination-btns:focus {
        color: #4B9598 !important;
      }
    }
.counts-div {
  width: 50%;
  margin: 0 auto;
  .label-span {
    // margin-right: 5px;
    color: #8898AA;
    font-size: 14px;
  }
  .count-span {
    color: $maroon;
    font-size: 20px;
    font-weight: bold;
  }
}

.deal-click {
  background-color: #2EA176;
  border: none;
  color: #FFFFFF;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 4px;
  box-shadow: 0px 3px 3px #00000007;
  width: 60%;
 margin-left: 100px;
  opacity: 1;
}
.sale-click {
  background-color: transparent;
  border: 1px solid #2EA176;
  color: #2EA176;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 20px;
  padding-left: 20px;
  font-size: 16px;
  border-radius: 4px;
  box-shadow:0px 3px 3px #00000007;;
  width: 60%;
  margin: 0 auto;
  opacity: 1;
}
}
  .normal-texts {
    display: block;
    // cursor: pointer !important;
    width: 100%;
    min-height: $input-height;
    padding: $input-padding-y $input-padding-x;
    // TODO make this work
    // font-family:$gotham  sans-serif!important;
    font-family: sans-serif;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    color: $maroon !important;
    // border-color: darken($input-border-color, 10%);
    background-color: white !important;
    outline: 0;
    white-space: nowrap !important;
  }
  .editable-texts {
    display: block;
    // cursor: pointer !important;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family:$gotham !important;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    background-clip: padding-box;
    border: none;
    border-radius: 5px;
    color: $maroon !important;
    border-color: darken($input-border-color, 10%);
    outline: 0;
    white-space: nowrap !important;

    img {
      position: relative;
      // cursor: pointer;
    }
    // box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.100);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    &:focus {
      color:  $maroon !important;
      background-color: white;
      border-color: darken($input-border-color, 10%);
      outline: 0;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
    }

    // Placeholder
    &::placeholder {
      color:  $maroon !important;
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
      background-color: $input-disabled-bg;
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: 1;
    }
  }

  .cell-dropdown-align {
    img {
      margin-top: 8px;
    }
  }

  .cell-dropdown {
    align-items: center;
    position: relative;
    // z-index: 0;

    img {
      position: relative;
      right: 0;
      margin-left: 15px;
      margin-right: 15px;
    }

    .drop-down {
      position: absolute;
      z-index: 100 !important;
       left: 0;
       top: 43px;
      background-color: $white;
      width: 200px;
      text-align: left;
      overflow: visible !important;
      text-overflow: ellipsis;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: $maroon;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }