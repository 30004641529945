.CRMDashboard {
  margin-top: 0 !important;

  .align-content-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-by {
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .filter-dropdown-btn {
    display: flex;
    position: relative;

    button {
      min-width: 100px;
      // text-align: center;
      width: fit-content;
      align-items: center;
      justify-content: center;

      img {
        margin-top: 4px;
        margin-left: 10px;
      }
    }
  }

  .performance-topic {
    h1 {
      font-family: "Gotham-Bold", sans-serif;
      font-size: 1.35rem;
      color: #172b4d;
    }
  }

  .performance-header {
    margin-bottom: 25px;
  }

  .stat-card-summary {
    margin-bottom: 40px;

    .figures {
      margin-top: 20px;

      .topic {
        h1 {
          max-width: 115px;
          font-size: 1.1em;
          font-family: "Gotham-Bold", sans-serif;
          font-weight: 600;
          color : #8898AA !important;
        }
      }

      .value {
        h1 {
          font-size: 1.5em;
        }
      }
    }

    .stat-header {
      margin-bottom: 20px;
      align-items: center;

      .topic {
        h1 {
          font-family: "Gotham-Bold", sans-serif;
          font-size: 1.35rem;
          color: #172b4d;
        }
      }
    }

    .filter {
      display: flex;
      flex-direction: row;

      .filter-container {
        position: relative;

        h1 {
          display: inline;
          font-size: 1.5em;
        }

        img {
          margin-top: 5px;
          margin-left: 15px;
          width: 15px;
        }

        .drop-down {
          position: absolute;
          right: -165px;
          top: 20px;
          background-color: $white;
          width: 160px;
          z-index: 1;

          ul {
            list-style-type: none;
            margin: 0;
            overflow-y: scroll;
            max-height: 200px;

            li {
              padding-left: 1em;
              padding-top: 0.25em;
              padding-bottom: 0.25em;
              color: black;

              &:hover {
                background-color: #e3e3e4;
              }
            }
          }
        }
      }
    }
  }

  .calendar-events {
    .header {
      margin-bottom: 25px;
      h1 {
        font-family: "Gotham-Bold", sans-serif;
        font-size: 1.35rem;
        color: #172b4d;
      }

      .btn-container {
        display: flex;
        flex: 1;
        justify-content: flex-end;
      }
    }
  }

  .align-item-left {
    display: flex;
    flex: 1;
    justify-content: flex-start;
  }

  .align-item-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
  }

  .align-item-center {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .event-stripe {
    height: 18px;
    width: 10px;
    background-color: #16395b;
    border-radius: 2px;
    display: inline-block;
  }

  .event-list-nav {
    margin-bottom: 25px;
  }

  .event-item {
    margin-top: 20px;
    margin-bottom: 20px;
    align-items: center;
    h1 {
      margin-right: 10px;
    }
  }
}
