// $style_space : 16em;
.scrollable-wrapper {
  transition: width 0.5s;
  height: calc(100vh - 60px);
  overflow-x: hidden;
  // display: flex;
  // flex-direction: column;
  // flex: 1;
  // background-color: blue;
  .content-header {
    padding-bottom: 0;
    // color: #fff;
    // background-color: $maroon;
    padding: 2em;
    padding-top: 20px;
    padding-bottom: 0;
    // padding-top: 1em;
    // padding-bottom: $style_space;
    // display: flex;
    // justify-content: center;
    align-items: center;
    .content-label {
      display: flex;
      flex-direction: row;
      flex: 2;
      align-items: center;
      img {
        width: 1.6em;
        margin-left: 10px;
        // margin-right: 10px;
      }
      h1 {
        // color: white!important;
        margin: 0;
        font-size: 1.5em;
        font-weight: 900;
      }
    }
    span{
      a {
        color: white;
        margin-left: 1em;
        margin-right: 1em;
        &:hover{
          text-decoration: none;
        }
      }
    }
    .content-function {
      flex: 1;
      display: flex;
      justify-content: flex-end;
    }
  }

  .content-body {
    position: relative;
    // top: -$style_space;
    // position: relative;
    padding: 2em;
    display: flex;
    flex-direction: column;
    // flex: 1;
    // background-color: magenta;
    // width: 100%;
  }

  .content {
    // padding: 2em;
    // padding-top: 1em;
    display: flex;
    flex-direction: column;
    // flex: 1;
    // background-color: yellow;
  }
}
