
.sales-funnel {
  display: flex;
  flex-direction: row;

  .main-card {
    z-index: 2;
    flex: 1;
    // height: calc(100vh - 200px);
    background-color: white;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    box-shadow: 0px 5px 5px 5px rgba(62, 57, 107, 0.07);

    .content {
      padding: 2rem;
    }

    .customer-cards {
      flex: 6;
      overflow-y: scroll;
    }

    .filter {
      flex: 1;
      border-top-right-radius: 2rem;
      border-bottom-right-radius: 2rem;
      background-color: #F7FAFC;
        ul {
          list-style: none;
          li{
            cursor: pointer;
            color: $text-blue;
            margin-left: 1rem;
          }
        }
    }
  }

  .side-card-container {
    z-index: 1;
    writing-mode: vertical-rl;
    text-orientation: mixed;

    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;

    .side-card {
      background-color: $white;
      padding: 1rem;
      color: #b2b9c4;
      font-size: 2rem;
    }
  }

  .left-card {
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;

  }

  .right-card {
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }
}