.scrollModal{
  display: none !important;
  position: absolute;
  left: 20px;
  right: 20px;
  top: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  background-color: #10172d;
  border-radius: 15px;
  padding: 2rem 3rem;
  height: 70vh;

  .close{
    color: white;
    font-size: 2rem;
    font-weight: bolder;
    position: absolute;
    right: 3%;
    top: 3%;
    cursor: pointer;
  }
  .title{
    font-size: 1.5rem;
    color: white !important;
    letter-spacing: 1px;
  }
  .circles{
    display: flex;
    justify-content: center;
    column-gap: 0.5rem;
    height: 100%;
    width: 100%;
    align-self: center;
    padding-top: 3rem;
    padding-bottom: 3rem;

    .circle{
      color: white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      box-shadow: 0px 0px 30px 5px #b1b9c957;
      min-height: 12rem;
      min-width: 12rem;
      max-width: 28rem;
      max-height: 28rem;
      text-align: center;
      .clusterName {
        font-weight: bold !important;
      }
      .suggestion{
        h6 {
          margin: -5px;
          padding: 0;
        }
      }
    }
    .circle-green{
      background-color: #53bea8;
      align-self: center;
      margin-left: 2rem;
    }
    .circle-sky{
      background-color: #17abbd;
      align-self: flex-start;
    }
    .circle-orangered{
      background-color: #e73b33;
      align-self: flex-end;
    }
    .circle-yellow{
      background-color: #eca52d;
      align-self: flex-start;
    }
    .circle-cyan{
      background-color: #3d9698;
      align-self: flex-end;
    }
  }
}

@keyframes scrollup {
 0%{opacity: 1;}
 70%{
    // top: 15%;
    top: 9vh;
  }
 100%{
    // top: 20%;
    top: 12vh;
    opacity: 1;
  }
}
@keyframes scrollDown {
  0%{
    opacity: 1;
    // top: 20%;
    top: 12vh ;
  }
  70%{
    // top: 15%;
    top: 9vh;
  }
  100%{
    // top: 100%;
    top: 100vh;
    opacity: 0;
  }
}

.scrollModal[data-scroll='1'] {
  animation: scrollup 1.8s ease-out forwards;
}

.scrollModal[data-scroll='0'] {
  animation: scrollDown 1.4s ease-in forwards;
}

.main-leads-label[data-scroll='1'] {
  background-image: linear-gradient(60deg, #89cff2, #ce5efa);
  background-clip: text;
  font-weight: bold !important;
  color: transparent !important;
  opacity: 1;
  transition: all 4s ease;
}

.main-content{
  background-color: #fff;
  transition: background-color 1.8s ease;
}
.main-content[data-dark='ON'] {
  background-color: #0f263e !important;
  // Couldn't add transition to background-image
  // background-image: linear-gradient(315deg, #191d36 0%, #0f263e 64%);
  // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  // background-size: 400% 400%;
  transition: background-color 1.8s ease-in-out;
}

.bottom-actions[data-dark='ON'] {
  opacity: 0;
  transition: 1.8s ease;
}
.react-table[data-dark='ON'] {
  opacity: 0;
  transition: all 2s ease;
}