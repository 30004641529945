// .paginationContainer {
//     // background-color: #F1F3F9;
//     float: right;
// }

// .contentContainers {
//   float: none;
// }
  
  .active a {
    color: white;
    float: left;
    padding: 5px 10px;
    margin: 0px 3px;
    font-size: 10px;
    text-decoration: none;
    border-radius: 100%;
    background-color: #DC1A30;
    border: none;
  }  
  
  .pagination {
    display: inline-block;
    padding: 0;
    margin: 0;
    float: right;
  }
  
  .pagination li {
    display: inline;
  }
  
  .inactive a {
    color: #DC1A30;
    float: left;
    padding: 5px 10px;
    margin: 0px 3px;
    font-size: 10px;
    text-decoration: none;
    border-radius: 100%;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
  }
  
  .btnPrevNext {
    color: #DC1A30;
    float: left;
    padding: 5px 10px;
    margin: 0px 3px;
    font-size: 10px;
    text-decoration: none;
    border-radius: 100%;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
  }
  
  .pagination li a:hover:not(.active) {
    background-color: #DC1A30;
    color: white;
    border: none;
  }
  
  
  .btnPrevNextInactive {
    width: 28px;
    color: #DC1A30;
    float: left;
    padding: 8px 8px;
    margin: 0px 3px;
    // font-size: 10px;
    text-decoration: none;
    border-radius: 100%;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
  }
  
  .btnPrevNextActive {
    width: 28px;
    color: #DC1A30;
    float: left;
    padding: 8px 8px;
    margin: 0px 3px;
    // font-size: 10px;
    text-decoration: none;
    border-radius: 100%;
    background-color: white;
    border: 1px solid rgba(0,0,0,0.1);
  }
  