.thumbsContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}

.thumb {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    // width: auto;
    margin-left: 15px;
    height: 200px;
    padding: 4px;
    box-sizing: border-box;
}

.thumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
}

.img {
    display: block;
    width: auto;
    height: 100%;
}

.mainContainer {
    display: inline-block;
    width: 100%;
}

.labelContainer {
    float: left;
}

.buttonContainer{
    // display: flex;
    // justify-content: flex-end;
    float: right;
}

.browseBtn {
    background-color: #4E0911;
    color: #FBD4D5;
    padding: 10px 15px;
    border: none;
    border-radius: 2px;
    // font-family: Gotham;
    font-size: 11px;
    opacity: 0.9;
    transition: 0.3s;
}