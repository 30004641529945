.Users {
  .user-level-cell {
    align-items: center;

    img {
      position: absolute;
      right: 0;
      margin-right: 10px;
      margin-top: 8px;
    }

    .drop-down {
      position: absolute;
      left: 0;
      top: 43px;
      background-color: $white;
      width: 100%;
      z-index: 100;
      text-align: left;
      overflow: hidden !important;

      ul {
        list-style-type: none;
        margin: 0;

        li {
          padding-left: 1em;
          padding-top: 0.25em;
          padding-bottom: 0.25em;
          color: black;

          &:hover {
            background-color: #e3e3e4;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
}
